.notification-container {
    position: relative;
    display: inline-block;
}

.notification-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.notification-bell-icon {
    font-size: 1.2rem;
    color: #504a5a;
}

.notification-badge {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #6908C9;
    color: white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.notification-panel {
    position: absolute;
    top: 100%;
    right: 0;
    width: 320px;
    max-height: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
}

.notification-header h3 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
}

.mark-all-read-button {
    background: none;
    border: none;
    color: #2196f3;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
}

.mark-all-read-button:hover {
    background-color: rgba(33, 150, 243, 0.1);
}

.mark-all-read-button:disabled {
    color: #a9a9a9;
    cursor: not-allowed;
    background-color: transparent;
}

.notification-list {
    overflow-y: auto;
    max-height: 340px;
}

.notification-item {
    display: flex;
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
}

.notification-item:hover {
    background-color: #f9f9f9;
}

.notification-item.unread {
    background-color: #f0f7ff;
}

.notification-item.unread:hover {
    background-color: #e3f2fd;
}

.notification-type-indicator {
    width: 4px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.notification-content {
    flex: 1;
    padding-left: 8px;
}

.notification-content p {
    margin: 0 0 4px 0;
    font-size: 0.9rem;
    line-height: 1.4;
}

.notification-time {
    font-size: 0.75rem;
    color: #757575;
}

.no-notifications {
    padding: 24px 16px;
    text-align: center;
    color: #757575;
    font-size: 0.9rem;
}

/* Add these new notification type colors */
.notification-success {
    background-color: #4caf50; /* Green */
}

.notification-danger {
    background-color: #f44336; /* Red */
}

.notification-warning {
    background-color: #ff9800; /* Orange */
}

.notification-info {
    background-color: #2196f3; /* Blue */
}

.notification-primary {
    background-color: #9c27b0; /* Purple */
}

/* Responsive styles */
@media (max-width: 768px) {
    .notification-panel {
        width: 280px;
        right: -70px;
    }
    
    .notification-panel:before {
        right: 85px;
    }
} 