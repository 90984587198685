/* .edit-employee-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: white;
    min-height: calc(100vh - 75px);
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding-top: 20px;
} */

/* .back-button-wrapper {
    align-self: flex-start;
    margin-bottom: 20px;
} */

.edit-employee-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.edit-employee-header {
    font-size: 28px;
    margin-bottom: 0.5rem;
    text-align: center;
    color: #6908C9;
    font-weight: 600;
    margin-top: 0;
}

.edit-employee-description {
    font-size: 16px;
    margin-bottom: 2rem;
    text-align: center;
    color: #504a5a;
    line-height: 1.6;
    max-width: 600px;
}

.edit-employee-form {
    background-color: white;
    padding: 2rem;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.edit-employee-form:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.08);
    transform: translateY(-2px);
}

.edit-employee-form div {
    margin-bottom: 1.2rem;
}

.edit-employee-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #504a5a;
}

.edit-employee-form input,
.edit-employee-form textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color 0.2s ease;
}

.edit-employee-form input:focus,
.edit-employee-form textarea:focus {
    outline: none;
    border-color: #6908C9;
    box-shadow: 0 0 0 2px rgba(105, 8, 201, 0.1);
}

.edit-employee-form textarea {
    min-height: 120px;
    resize: vertical;
}

.edit-employee-save-button {
    width: 100%;
    padding: 0.8rem 1.5rem;
    background-color: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    transition: all 0.2s ease;
    margin-top: 0.5rem;
}

.edit-employee-save-button:hover {
    background-color: #6908C9;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(105, 8, 201, 0.15);
}

.edit-employee-save-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.edit-employee-archive-button {
    padding: 0.8rem 1.5rem;
    background-color: white;
    color: #dc3545;
    border: 2px solid #dc3545;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease;
    margin-top: 0.5rem;
    max-width: 500px;
    width: 100%;
}

.edit-employee-archive-button:hover {
    background-color: #dc3545;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(220, 53, 69, 0.15);
}

.edit-employee-archive-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .edit-employee-content {
        padding: 0 1rem;
    }
    
    .edit-employee-form {
        padding: 1.5rem;
    }
    
    .edit-employee-header {
        font-size: 24px;
    }
}
