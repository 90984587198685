.careers-body {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    position: relative;
    z-index: 1;
    padding-top: 40px; /* Add space between navbar and content */
}

.careers-title {
    font-size: 28px;
    margin-bottom: 1.5rem;
    color: #6908C9;
    text-align: center;
    font-weight: 600;
}

.careers-container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid #dcdcdc;
    color: #504a5a;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease;
}

.careers-container:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.08);
    transform: translateY(-2px);
}

.careers-section {
    margin-bottom: 2rem;
}

.careers-subtitle {
    font-size: 22px;
    margin-bottom: 1rem;
    color: #6908C9;
    font-weight: 500;
}

.careers-text {
    font-size: 16px;
    line-height: 1.6;
    color: #504a5a;
    margin-bottom: 1.5rem;
}

.careers-link {
    color: #6908C9;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
    position: relative;
}

.careers-link:hover {
    color: #6908C9;
    text-decoration: none;
}

.careers-link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #6908C9;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.careers-link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.careers-dropdown {
    width: 100%;
    max-width: 300px;
    padding: 0.8rem;
    margin-bottom: 1.5rem;
    font-size: 16px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #f8f8f8;
    color: #777;
    cursor: not-allowed;
}

.careers-cta {
    background-color: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    padding: 0.8rem 1.5rem;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 500;
    margin-top: 1rem;
}

.careers-cta:hover {
    background-color: #6908C9;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(105, 8, 201, 0.15);
}

.no-openings-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 1rem 0;
    border-left: 4px solid #6908C9;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.no-openings-icon {
    color: #6908C9;
    font-size: 24px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .careers-container {
        padding: 1.5rem;
    }
    
    .careers-title {
        font-size: 24px;
    }
    
    .careers-subtitle {
        font-size: 20px;
    }
    
    .careers-text {
        font-size: 15px;
    }
}

