.store-manager-page {
    width: 100%;
}

/* Adjust the store manager content to fit with the page layout */
.page-container .store-manager-container {
    padding: 0;
    width: 100%;
    max-width: 100%;
}

/* Hide the duplicated title in the StoreManager component */
.page-container .store-manager-container .store-navigation h1 {
    display: none;
}

/* Position the tabs correctly */
.page-container .store-manager-container .store-manager-header {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
}

/* Hide the back button in the StoreManager component when the page has its own */
.page-container .store-manager-container .back-button {
    display: none;
}

/* Style section headers consistently */
.page-container .store-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.page-container .store-section-header h2 {
    margin: 0;
    color: #6908C9;
    font-size: 1.5rem;
}

/* Adjust the notification to fit within our page */
.page-container .store-notification {
    margin-bottom: 1.5rem;
}

/* Style the add item button consistent with the app branding */
.page-container .add-item-button {
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.page-container .add-item-button:hover {
    background-color: #5607a3;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.1);
}

/* Make store tables fit within the page content */
.page-container .store-items-table-container,
.page-container .redemptions-table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 2rem;
}

/* Ensure form modal works with the page structure */
.store-form-modal {
    z-index: 1100;
}

/* Handle responsive styling */
@media screen and (max-width: 768px) {
    .page-container .store-manager-container {
        padding: 0;
    }
    
    .page-container .store-manager-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .page-container .store-manager-tabs {
        width: 100%;
        display: flex;
    }
    
    .page-container .store-tab {
        flex: 1;
        justify-content: center;
    }
    
    .page-container .store-section-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .page-container .store-section-header h2 {
        margin-bottom: 1rem;
    }
    
    .page-container .add-item-button {
        width: 100%;
    }
}

.page-container .store-tab {
    transition: all 0.2s ease;
}

.page-container .store-tab:hover:not(.active) {
    background: #e0e0e0;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.page-container .store-tab.active:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.2);
} 