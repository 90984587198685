/* Store Container */
.company-store-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

/* Store Header */
.store-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.store-navigation {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.store-header h1 {
    margin: 0;
    font-size: 1.75rem;
    color: #333;
}

.user-points {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #f9f0ff;
    padding: 0.75rem 1.25rem;
    border-radius: 8px;
    font-size: 1.1rem;
}

.coins-icon {
    color: #ffc107;
}

.history-button {
    background: #6908c9;
    color: white;
    border: none;
    padding: 0.75rem 1.25rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.history-button:hover {
    background: #5a07aa;
}

/* Store Description */
.store-description {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    color: #555;
}

/* Store Items Grid */
.store-items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

/* Store Item Card */
.store-item {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    background: white;
    cursor: pointer;
}

.store-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.store-item.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.store-item.disabled:hover {
    transform: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.store-item-image-container {
    height: 200px;
    overflow: hidden;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.store-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.no-image {
    font-size: 3rem;
    color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.store-item-details {
    padding: 1.25rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.store-item h3 {
    margin: 0 0 0.5rem;
    font-size: 1.25rem;
    color: #333;
}

.store-item p {
    margin: 0 0 1rem;
    color: #666;
    flex-grow: 1;
}

.store-item-points {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #6908c9;
}

.store-coins-icon {
    color: #ffc107;
}

.store-item-quantity {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
}

.out-of-stock {
    color: #f44336;
    font-weight: 500;
}

.not-enough-points {
    font-size: 0.9rem;
    color: #f44336;
    margin-bottom: 0.5rem;
}

.redeem-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background: #6908c9;
    color: white;
    border: none;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
}

.redeem-button:hover:not(:disabled) {
    background: #5a07aa;
}

.redeem-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

/* Confirmation Modal */
.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.confirmation-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.confirmation-content h2 {
    margin-top: 0;
    color: #333;
}

.confirmation-details {
    display: flex;
    gap: 1.5rem;
    margin: 1.5rem 0;
}

.confirmation-item-image {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 8px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmation-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.confirmation-points {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.confirmation-points div {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
}

.remaining-points {
    font-weight: 600;
    color: #6908c9;
}

.confirmation-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.confirm-button, .cancel-button {
    flex: 1;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.confirm-button {
    background: #6908c9;
    color: white;
}

.confirm-button:hover {
    background: #5a07aa;
}

.cancel-button {
    background: #f2f2f2;
    color: #333;
}

.cancel-button:hover {
    background: #e0e0e0;
}

/* Loading and Error States */
.store-loading, .store-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    gap: 1rem;
}

.loading-spinner {
    font-size: 2rem;
    color: #6908c9;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.store-error button {
    background: #6908c9;
    color: white;
    border: none;
    padding: 0.75rem 1.25rem;
    border-radius: 4px;
    cursor: pointer;
}

/* No Items Message */
.no-items {
    text-align: center;
    padding: 3rem;
    background: #f9f9f9;
    border-radius: 8px;
    color: #666;
}

/* Redemption Message */
.redemption-message {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    text-align: center;
}

.redemption-message.success {
    background: #e8f5e9;
    color: #2e7d32;
    border: 1px solid #a5d6a7;
}

.redemption-message.error {
    background: #ffebee;
    color: #c62828;
    border: 1px solid #ef9a9a;
}

/* Redemption History */
.redemption-history {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
}

.redemption-history h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
}

.no-history {
    text-align: center;
    color: #666;
    padding: 2rem;
}

.history-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.history-item {
    display: flex;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.history-item-image-container {
    width: 100px;
    height: 100px;
    overflow: hidden;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.history-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.history-item-details {
    padding: 1rem;
    flex-grow: 1;
}

.history-item h3 {
    margin: 0 0 0.25rem;
    font-size: 1.1rem;
}

.history-item p {
    margin: 0 0 0.5rem;
    font-size: 0.9rem;
    color: #777;
}

.history-item-points {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.history-coins-icon {
    color: #ffc107;
    font-size: 0.9rem;
}

.history-status {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
}

.history-status.pending {
    background: #fff3e0;
    color: #e65100;
}

.history-status.fulfilled {
    background: #e8f5e9;
    color: #2e7d32;
}

.history-status.rejected {
    background: #ffebee;
    color: #c62828;
}

.back-to-store-button {
    display: block;
    margin: 1.5rem auto 0;
    background: #6908c9;
    color: white;
    border: none;
    padding: 0.75rem 1.25rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.back-to-store-button:hover {
    background: #5a07aa;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .company-store-container {
        padding: 1rem;
    }
    
    .store-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .store-navigation {
        width: 100%;
        justify-content: space-between;
    }
    
    .user-points {
        width: 100%;
        justify-content: center;
    }
    
    .store-actions {
        width: 100%;
    }
    
    .history-button {
        width: 100%;
    }
    
    .store-items-grid {
        grid-template-columns: 1fr;
    }
    
    .confirmation-details {
        flex-direction: column;
    }
    
    .confirmation-item-image {
        width: 100%;
        height: 150px;
    }
    
    .history-item {
        flex-direction: column;
    }
    
    .history-item-image-container {
        width: 100%;
        height: 150px;
    }
}

/* Store Manager Styles */
.store-manager-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.store-manager-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.store-manager-tabs {
    display: flex;
    gap: 1rem;
}

.store-tab {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #f2f2f2;
    color: #333;
}

.store-tab.active {
    background: #6908c9;
    color: white;
}

.store-tab:hover:not(.active) {
    background: #e0e0e0;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.store-tab.active:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.2);
}

.store-notification {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    position: relative;
}

.store-notification.success {
    background: #e8f5e9;
    color: #2e7d32;
    border: 1px solid #a5d6a7;
}

.store-notification.error {
    background: #ffebee;
    color: #c62828;
    border: 1px solid #ef9a9a;
}

.close-notification {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    font-size: 1.25rem;
    line-height: 1;
    cursor: pointer;
    opacity: 0.7;
}

.close-notification:hover {
    opacity: 1;
}

.store-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.add-item-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #6908c9;
    color: white;
    border: none;
    padding: 0.75rem 1.25rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.add-item-button:hover {
    background: #5a07aa;
}

.no-items-message {
    text-align: center;
    padding: 3rem;
    background: #f9f9f9;
    border-radius: 8px;
    color: #666;
}

/* Table Styles */
.store-items-table-container, .redemptions-table-container {
    overflow-x: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.store-items-table, .redemptions-table {
    width: 100%;
    border-collapse: collapse;
}

.store-items-table th, .store-items-table td,
.redemptions-table th, .redemptions-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.store-items-table th, .redemptions-table th {
    font-weight: 600;
    color: #333;
    background: #f9f9f9;
}

.item-image-cell {
    width: 80px;
}

.table-item-image, .redemption-item-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
}

.no-image-small {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f0f0;
    color: #aaa;
    border-radius: 4px;
    font-size: 1.5rem;
}

.description-cell {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.points-cell {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
}

.points-icon {
    color: #ffc107;
}

.item-status {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.85rem;
    font-weight: 500;
}

.item-status.active {
    background: #e8f5e9;
    color: #2e7d32;
}

.item-status.inactive {
    background: #f5f5f5;
    color: #757575;
}

.item-actions {
    display: flex;
    gap: 0.5rem;
}

.edit-button, .delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.edit-button {
    background: #f0f4ff;
    color: #2196f3;
}

.edit-button:hover {
    background: #e1eaff;
}

.delete-button {
    background: #ffebee;
    color: #f44336;
}

.delete-button:hover {
    background: #ffdbdb;
}

.inactive-item {
    opacity: 0.6;
}

/* Redemption Styles */
.redemption-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.employee-email {
    font-size: 0.8rem;
    color: #777;
    margin-top: 0.25rem;
}

.redemption-status {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.85rem;
    font-weight: 500;
}

.redemption-status.pending {
    background: #fff3e0;
    color: #e65100;
}

.redemption-status.fulfilled {
    background: #e8f5e9;
    color: #2e7d32;
}

.redemption-status.rejected {
    background: #ffebee;
    color: #c62828;
}

.redemption-actions {
    display: flex;
    gap: 0.5rem;
}

.fulfill-button, .reject-button {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.fulfill-button {
    background: #e8f5e9;
    color: #2e7d32;
}

.fulfill-button:hover {
    background: #c8e6c9;
}

.reject-button {
    background: #ffebee;
    color: #c62828;
}

.reject-button:hover {
    background: #ffcdd2;
}

/* Form Modal */
.store-form-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem;
}

.store-form-container {
    background: white;
    border-radius: 8px;
    width: 100%;
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 2rem;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.store-form-container h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #333;
}

.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.form-group input.error, .form-group textarea.error {
    border-color: #f44336;
}

.error-message {
    color: #f44336;
    font-size: 0.85rem;
    margin-top: 0.25rem;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.checkbox-group {
    display: flex;
    align-items: center;
}

.checkbox-group label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0;
    cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
    width: auto;
}

.image-upload-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.image-preview {
    width: 100%;
    height: 200px;
    border: 2px dashed #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    transition: border-color 0.2s;
}

.image-preview:hover {
    border-color: #6908c9;
}

.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-upload-instructions {
    text-align: center;
    color: #666;
}

.image-hint {
    font-size: 0.85rem;
    color: #999;
    margin-top: 0.25rem;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.save-button, .form-actions .cancel-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.save-button {
    background: #6908c9;
    color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.save-button:hover {
    background: #5a07aa;
}

.form-actions .cancel-button {
    background: #f2f2f2;
    color: #333;
}

.form-actions .cancel-button:hover {
    background: #e0e0e0;
}

.loading-spinner-small {
    animation: spin 1s linear infinite;
    font-size: 1rem;
}

/* Responsive Adjustments for Store Manager */
@media (max-width: 992px) {
    .form-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .store-manager-container {
        padding: 1rem;
    }
    
    .store-manager-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .store-manager-tabs {
        width: 100%;
    }
    
    .store-tab {
        flex: 1;
        justify-content: center;
    }
    
    .store-section-header {
        flex-direction: column;
        gap: 1rem;
    }
    
    .add-item-button {
        width: 100%;
        justify-content: center;
    }
    
    .form-row {
        grid-template-columns: 1fr;
    }
    
    .form-actions {
        flex-direction: column;
    }
    
    .save-button, .form-actions .cancel-button {
        width: 100%;
        justify-content: center;
    }
} 