/* ========================================
   EmployeeMaster.js - Main Container Styles
   ======================================== */
.employee-goal-set-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.employee-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: white;
}

.employee-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.employee-goal-set-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

/* ========================================
   Loading States
   ======================================== */
.employee-loading-overlay {
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.employee-loading-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.employee-loading-text {
    margin-top: 20px;
    color: black;
    font-size: 1.2rem;
    font-weight: 500;
}

/* ========================================
   Common Text and Button Styles
   ======================================== */
.employee-goal-set-title {
    color: black;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.employee-goal-set-subtitle {
    color: #504a5a;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2rem;
}

.employee-goal-set-description {
    color: #504a5a;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2rem;
    max-width: 750px;
    width: 100%;
}

.employee-goal-set-button-container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    gap: 20px;
    flex-wrap: wrap;
}

.employee-goal-set-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    min-width: auto;
    width: auto;
}

.employee-goal-set-link:hover {
    color: #d1c4e9;
}

.employee-goal-set-submit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.employee-goal-set-submit-link:hover {
    color: #d1c4e9;
}

/* ========================================
   EmployeeStep1.js - Mission and Goals Review
   ======================================== */
.employee-goal-review-mission-title,
.employee-goal-review-goals-title {
    color: black;
    font-size: 24px;
    margin-bottom: 1rem;
}

.employee-goal-review-text {
    color: #504a5a;
    font-size: 16px;
    margin-bottom: 1rem;
}

.employee-mission-card {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 2rem;
    margin: 1rem auto;
    width: 100%;
    max-width: 750px;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    transition: all 0.2s ease;
}

.employee-mission-card:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.employee-goal-review-mission {
    font-size: 16px;
    color: #504a5a;
    flex: 1;
}

.employee-goals-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    width: 100%;
    max-width: 750px;
    margin: 1rem auto;
}

.employee-goal-card {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;
    transition: all 0.2s ease;
}

.employee-goal-card:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

/* Updated goal number style to match manager style */
.employee-goal-card .employee-goal-number {
    font-size: 20px;
    font-weight: 500;
    color: #6908C9;
    position: static;
    background: none;
    width: auto;
    height: auto;
    border-radius: 0;
    display: inline;
    margin-right: 10px;
}

.employee-goal-review-goals-list {
    max-width: 750px;
    list-style: none;
    padding: 0;
    margin: 0 auto;
}

.employee-goal-set-list-item {
    font-size: 16px;
    color: #504a5a;
    padding: 1rem;
    margin-bottom: 0.5rem;
    background: #faf7fd;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.employee-goal-set-list-item:before {
    content: "\2605";
    color: #6908C9;
    margin-right: 8px;
}

/* ========================================
   EmployeeStep2.js - Employee Context Form
   ======================================== */
.employee-goal-set-flex-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 800px;
    margin: 1rem 0;
}

/* Progress Indicator */
.employee-step-progress {
    margin: 30px auto;
    width: 100%;
    max-width: 800px;
    padding: 0 20px;
}

.employee-step-progress-bar {
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
}

.employee-step-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 4px;
    transition: width 0.3s ease;
}

.employee-step-progress-text {
    font-size: 14px;
    color: #666;
    text-align: right;
}

/* Form Sections */
.employee-team-context-form {
    width: 100%;
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.employee-team-context-form-section {
    margin-bottom: 20px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    border: none;
    box-shadow: none;
}

.employee-team-context-form-section-title {
    color: black;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    border-bottom: none;
    padding-bottom: 0;
    text-align: center;
    width: 100%;
}

/* Form Inputs */
.employee-team-context-label {
    font-size: 16px;
    color: #504a5a;
    margin-bottom: 10px;
    align-self: flex-start;
    margin-left: 10%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    width: 80%;
    max-width: 700px;
}

.employee-team-context-input {
    width: 80%;
    max-width: 700px;
    min-height: 150px;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    margin: 0.5rem auto 2rem;
    display: block;
}

.employee-character-count {
    font-size: 12px;
    color: #6c757d;
    position: absolute;
    right: 0;
    white-space: nowrap;
}

.employee-tooltip-icon {
    color: #504a5a;
    cursor: help;
    font-size: 14px;
}

/* Selectors */
.employee-team-size-selector,
.employee-work-style-selector,
.employee-skill-level-selector {
    display: flex;
    width: 80%;
    max-width: 700px;
    margin: 1rem 0 2rem;
    gap: 1rem;
    justify-content: space-between;
    align-self: center;
}

.employee-style-option {
    flex: 1;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    height: 100px;
    justify-content: center;
}

.employee-style-option:hover {
    border-color: #6908C9;
    background-color: #f9f5ff;
}

.employee-style-option.selected {
    border-color: #6908C9;
    background-color: #f9f5ff;
    box-shadow: 0 0 0 2px rgba(105, 8, 201, 0.2);
}

.employee-style-option svg {
    font-size: 24px;
    margin-bottom: 8px;
    color: #6908C9;
}

.employee-style-option span {
    display: block;
    font-size: 14px;
}

/* Challenges Grid */
.employee-challenges-grid {
    width: 80%;
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    margin: 1rem 0 2rem;
    align-self: center;
}

.employee-challenge-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    user-select: none;
}

.employee-challenge-option:hover {
    border-color: #6908C9;
    background: #eddef8;
}

.employee-challenge-option input {
    margin-right: 10px;
}

/* Confirmation View */
.employee-confirmation-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 20px;
}

.employee-confirmation-section {
    margin-bottom: 20px;
}

.employee-confirmation-section h5 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #6908C9;
}

.employee-confirmation-item {
    margin-bottom: 8px;
    padding: 8px;
    background-color: #fff;
    border-radius: 4px;
    border-left: 3px solid #6908C9;
}

.employee-confirmation-section ul {
    list-style-type: none;
    padding-left: 0;
}

.employee-confirmation-section li {
    padding: 8px;
    margin-bottom: 5px;
    background-color: #fff;
    border-radius: 4px;
    border-left: 3px solid #6908C9;
}

/* ========================================
   EmployeeStep3.js - Goal Setting
   ======================================== */
.employee-goals-container {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

.employee-goal-item {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

.employee-goal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: calc(100% - 35px); /* Account for goal number width and gap */
    box-sizing: border-box;
}

.employee-goal-set-input {
    width: 100%;
    min-height: 150px;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    box-sizing: border-box;
    margin: 0;
}

/* Fix alignment of action buttons */
.employee-goal-item .employee-goal-actions {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin: 10px 0 0 0;
    padding: 0;
    justify-content: flex-start;
}

/* Ensure the main goal actions (AI button and Add Manual Goal) are centered */
.employee-goal-set-container > .employee-goal-actions {
    width: 100%;
    max-width: 750px;
    display: flex;
    gap: 1.5rem;
    margin: 10px auto 2rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;
}

/* Use the astellyst-ai-button class from AIButton.css */
.employee-goal-set-add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    background: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 180px;
    max-width: 250px;
    width: auto;
}

.employee-goal-set-add-button:hover {
    background: #eddef8;
    transform: translateY(-1px);
}

/* Context Summary */
.employee-context-summary {
    background-color: #f9f5ff;
    border-radius: 8px;
    padding: 20px 25px;
    margin: 0 auto 30px;
    border: 1px solid #e6d8ff;
    width: 90%;
    max-width: 750px;
    box-sizing: border-box;
}

.employee-context-summary h4 {
    color: #6908C9;
    margin-bottom: 40px;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
}

.employee-context-summary-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 30px;
    margin-bottom: 15px;
}

.employee-context-summary-content p {
    margin: 5px 0;
    font-size: 14px;
    line-height: 1.4;
    overflow: visible;
    word-wrap: break-word;
}

.employee-context-note {
    font-size: 13px;
    color: #666;
    margin-top: 15px;
    font-style: italic;
    text-align: center;
    line-height: 1.4;
}

/* ========================================
   EmployeeStep4.js - Review and Submit
   ======================================== */
.employee-goal-set-review-container {
    width: 90%;
    max-width: 750px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.employee-goals-review-container {
    width: 100%;
}

.employee-goals-edit-list,
.employee-goals-display-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.employee-goal-review-item {
    width: 100%;
    display: flex;
    gap: 1rem;
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1.5rem;
    transition: all 0.2s ease;
    box-sizing: border-box;
}

.employee-goal-review-item:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.employee-goal-review-item.editing {
    border-color: #6908C9;
    background-color: #faf7fd;
}

.employee-goal-review-content {
    flex: 1;
    font-size: 16px;
    line-height: 1.6;
    color: #504a5a;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding-right: 10px;
}

.employee-edit-goals-button {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    background: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 1.5rem auto;
}

.employee-edit-goals-button:hover {
    background: #6908C9;
    color: white;
    transform: translateY(-1px);
}

/* Fix text areas extending too far in editing mode */
.employee-goal-review-item.editing .employee-goal-content {
    flex: 1;
    width: 100%;
}

.employee-edit-done-button {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    background: #6908C9;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 1.5rem auto;
}

.employee-edit-done-button:hover {
    background: #5607a3;
    transform: translateY(-1px);
}

/* Fix edit and delete button alignment */
.employee-action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 0;
}

.employee-action-button.confirm {
    background: #6908C9;
    color: white;
    border: none;
}

.employee-action-button.edit {
    background: white;
    color: #6908C9;
    border: 1px solid #6908C9;
}

.employee-action-button.delete {
    background: white;
    color: #dc3545;
    border: 1px solid #dc3545;
}

.employee-action-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

/* ========================================
   Media Queries - Responsive Design
   ======================================== */
@media screen and (max-width: 768px) {
    .employee-goal-set-flex-container,
    .employee-goal-set-review-container {
        width: calc(100% - 30px);
        margin: 1rem 15px;
    }

    .employee-goal-set-button-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }

    .employee-goal-set-container {
        padding: 1rem;
    }

    .employee-page-content {
        padding: 1rem;
    }

    .employee-goals-container,
    .employee-goal-set-review-container {
        padding: 0 1rem;
    }

    .employee-goal-review-mission,
    .employee-goal-review-goals-list {
        width: calc(100% - 30px);
        margin: 1rem 15px;
    }

    .employee-work-style-selector, .employee-skill-level-selector {
        flex-direction: column;
    }

    .employee-team-context-label {
        width: 90%;
        margin-left: 5%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .employee-character-count {
        position: static;
        margin-left: auto;
    }
    
    .employee-team-context-input {
        width: 90%;
    }

    .employee-context-summary-content {
        grid-template-columns: 1fr;
    }
    
    .employee-goal-actions {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    
    .astellyst-ai-button,
    .employee-goal-set-add-button {
        width: auto;
        min-width: 160px;
    }

    .employee-context-summary-content {
        grid-template-columns: 1fr;
    }
    
    .employee-goal-set-review-container,
    .employee-context-summary {
        width: 95%;
    }
}

@media screen and (max-width: 480px) {
    .employee-goal-set-title {
        font-size: 24px;
    }

    .employee-goal-set-link,
    .employee-goal-set-submit-link {
        font-size: 16px;
    }

    .employee-loading-text {
        font-size: 1rem;
        padding: 0 15px;
    }

    .employee-context-summary-content {
        grid-template-columns: 1fr;
    }

    .employee-challenges-grid {
        grid-template-columns: 1fr;
    }

    .employee-goal-set-button-container {
        gap: 15px;
    }
    
    .employee-goal-set-link {
        font-size: 16px;
        padding: 8px 15px;
    }
}

