.manager-portal {
    display: flex;
    font-family: 'Literata', serif;
    background-color: white;
    min-height: 100vh;
}

.mp-portal-sidebar {
    width: 250px;
    background-color: #f8f8f8;
    padding: 2rem 1rem;
    height: 100vh;
    border-right: 1px solid #dcdcdc;
    transition: all 0.3s ease;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
}

.mp-portal-sidebar-button {
    font-family: 'Literata', serif;
    font-size: 16px;
    border: none;
    background-color: transparent;
    display: flex;
    padding: 1rem 1.2rem;
    text-decoration: none;
    color: #504a5a;
    margin-bottom: 0.8rem;
    width: 100%;
    border-radius: 8px;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    justify-content: flex-start;
    text-align: left;
}

.mp-portal-sidebar-button:hover {
    background-color: #eddef8;
    color: #6908C9;
    text-decoration: none;
    transform: translateX(3px);
}

.mp-portal-sidebar-button:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: #6908C9;
    transform: scaleY(0);
    transition: transform 0.2s ease;
}

.mp-portal-sidebar-button:hover:before {
    transform: scaleY(1);
}

.mp-portal-content {
    flex-grow: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.mp-portal-menu-button {
    display: none;
    font-size: 18px;
    font-family: 'Literata', serif;
    cursor: pointer;
    margin: 10px;
    background: none;
    border: none;
    color: #6908C9;
    transition: color 0.3s;
}

.mp-portal-menu-button:hover {
    color: #d1c4e9;
}

.mp-portal-sidebar.mobile {
    display: none;
    position: fixed;
    top: 0;
    left: -250px;
    width: 200px;
    height: 100%;
    background-color: #f4f4f4;
    transition: left 0.3s ease;
    z-index: 1000;
}

.mp-portal-sidebar.mobile.open {
    left: 0;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .mp-portal-menu-button {
        display: flex;
        margin-top: 20px;
    }

    .mp-portal-sidebar {
        display: none;
    }

    .mp-portal-sidebar.mobile {
        display: block;
    }

    .mp-portal-sidebar-button, 
    .mp-portal-sidebar.mobile .mp-portal-sidebar-button {
        justify-content: flex-start;
        text-align: left;
    }
}

/* Primary purple buttons for portal actions */
.mp-goal-setting-button {
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.mp-goal-setting-button:hover {
    background-color: #5607a3;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.1);
}

/* White buttons specifically for dashboard cards */
.mp-dashboard-button {
    background-color: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    padding: 10px 18px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.mp-dashboard-button:hover {
    background-color: #eddef8;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.1);
}

.mp-goal-setting-button-container {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

/* Content Section Styles */
.portal-section {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
}

.portal-section:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.08);
    transform: translateY(-2px);
}

.portal-section h2 {
    color: #6908C9;
    font-size: 24px;
    margin-bottom: 1rem;
    font-weight: 600;
}

.portal-section p {
    color: #504a5a;
    font-size: 16px;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

/* Active sidebar item styling */
.mp-portal-sidebar-button.active {
    background-color: #eddef8;
    color: #6908C9;
    font-weight: 500;
}

.mp-portal-sidebar-button.active:before {
    transform: scaleY(1);
}

/* This ensures both hover and active states work correctly */
.mp-portal-sidebar-button:hover:not(.active) {
    background-color: #eddef8;
    color: #6908C9;
    text-decoration: none;
    transform: translateX(3px);
}

.mp-portal-sidebar-button:hover:not(.active):before {
    transform: scaleY(1);
}

/* Manager Dashboard Grid Layout */
.mp-dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin: 1.5rem 0;
}

/* For mobile screens */
@media screen and (max-width: 768px) {
    .mp-dashboard-grid {
        grid-template-columns: 1fr;
    }
}

/* Dashboard Card Styles */
.mp-dashboard-card {
    background: #f8f5fd;
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid #e0d5f0;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.mp-dashboard-card:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.1);
    transform: translateY(-3px);
}

.mp-dashboard-card-header {
    margin-bottom: 1.2rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #e0d5f0;
}

.mp-dashboard-card-header h3 {
    color: #504a5a;
    font-size: 18px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
}

.mp-dashboard-card .card-icon {
    color: #6908C9;
}

.mp-dashboard-buttons {
    margin-top: auto;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}

.mp-dashboard-loading {
    text-align: center;
    padding: 2rem 0;
    color: #888;
    font-style: italic;
}

.mp-no-data-message {
    text-align: center;
    padding: 2rem 0;
    color: #888;
    font-style: italic;
}

/* Stats Grid */
.mp-stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
}

.mp-stat-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.mp-stat-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mp-stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #6908C9;
}

.mp-stat-label {
    font-size: 14px;
    color: #504a5a;
    margin-top: 0.5rem;
    text-align: center;
}

/* Check-ins Styles */
.mp-checkins-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.mp-checkin-item {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.mp-checkin-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mp-checkin-date {
    font-weight: 500;
    margin-bottom: 0.3rem;
    color: #6908C9;
}

.mp-checkin-employee {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

.mp-checkin-notes {
    padding-top: 0.5rem;
    border-top: 1px dashed #e0e0e0;
    font-size: 0.9rem;
    font-style: italic;
    color: #666;
}

/* Goals Styles */
.mp-goals-summary {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.mp-goals-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.mp-goals-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
}

.mp-goal-item {
    background: white;
    border-radius: 8px;
    padding: 1.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.mp-goal-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mp-goal-summary {
    margin-bottom: 1rem;
    font-weight: 500;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    line-height: 1.4;
    color: #333;
}

.mp-goal-progress-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 0.75rem;
}

.mp-goal-progress-bar {
    flex: 1;
    height: 10px;
    background-color: #eeeeee;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.mp-goal-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 5px;
    transition: width 0.5s ease;
}

.mp-goal-progress-text {
    font-size: 0.9rem;
    font-weight: 600;
    color: #6908C9;
    min-width: 45px;
    text-align: right;
}

/* Employee progress highlights */
.mp-progress-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.mp-progress-item {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.mp-progress-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mp-progress-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
}

.mp-progress-employee {
    font-weight: 500;
    color: #333;
}

.mp-progress-percentage {
    font-weight: 700;
    color: #6908C9;
}

.mp-progress-summary {
    font-size: 15px;
    color: #444;
    margin-bottom: 10px;
    word-break: break-word;
    white-space: normal;
    line-height: 1.4;
    padding: 0 2px;
}

.mp-dashboard-card.team-goals,
.mp-dashboard-card.personal-goals {
    background-color: #f9f6ff;
}

.mp-dashboard-card .mp-goals-list {
    margin-bottom: 1.5rem;
}

/* Archived goals display */
.mp-archived-goals-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.mp-archived-section {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1.5rem;
}

.mp-archived-section h3 {
    color: #6908C9;
    margin-bottom: 1rem;
}

.mp-archived-goals-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

.mp-archived-goal-card {
    background-color: #f9f6ff;
    border: 1px solid #e0d5f0;
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.2s ease;
}

.mp-archived-goal-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
}

.mp-archived-goal-content h4 {
    color: #504a5a;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.mp-archived-goal-progress {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.8rem 0;
}

.mp-archived-progress-bar {
    flex: 1;
    height: 8px;
    background-color: #eeeeee;
    border-radius: 4px;
    overflow: hidden;
}

.mp-archived-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 4px;
}

.mp-archived-progress-text {
    font-size: 0.85rem;
    font-weight: 600;
    color: #6908C9;
    min-width: 40px;
    text-align: right;
}

.mp-archived-date {
    font-size: 0.75rem;
    color: #888;
    margin-top: 0.5rem;
    font-style: italic;
}

.mp-no-archived {
    color: #888;
    font-style: italic;
    text-align: center;
    padding: 1rem;
}

.mp-loading {
    text-align: center;
    padding: 2rem;
    color: #888;
    font-style: italic;
}

/* Unarchive button styling */
.mp-archived-actions {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.mp-unarchive-button {
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.mp-unarchive-button:hover {
    background-color: #5607A3;
    transform: translateY(-2px);
}

/* Add rules for disabled buttons */
.mp-portal-sidebar-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #f0f0f0;
    color: #999;
}

.mp-portal-sidebar-button.disabled:hover {
    background-color: #f0f0f0;
    color: #999;
    transform: none;
    box-shadow: none;
}

.mp-portal-sidebar-button.disabled:before {
    display: none;
}
