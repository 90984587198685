/* SalesQuotePage.css */

.sales-quote-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: white;
    min-height: calc(100vh - 75px); /* Match navbar height exactly */
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1; /* Ensure content is above any shadows */
    padding-top: 40px; /* Add space between navbar and content */
}

.sales-quote-header {
    font-size: 28px;
    margin-bottom: 0.5rem;
    text-align: center;
    color: #6908C9;
    font-weight: 600;
    margin-top: 0; /* Reset top margin */
}

.sales-quote-description {
    font-size: 16px;
    margin-bottom: 2rem;
    text-align: center;
    color: #504a5a;
    line-height: 1.6;
    max-width: 600px;
}

.sales-quote-form {
    background-color: white;
    padding: 2rem;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.sales-quote-form:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.08);
    transform: translateY(-2px);
}

.sales-quote-form div {
    margin-bottom: 1.2rem;
}

.sales-quote-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #504a5a;
}

.sales-quote-form input,
.sales-quote-form textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    transition: border-color 0.2s ease;
}

.sales-quote-form input:focus,
.sales-quote-form textarea:focus {
    outline: none;
    border-color: #6908C9;
    box-shadow: 0 0 0 2px rgba(105, 8, 201, 0.1);
}

.sales-quote-form button {
    width: 100%;
    padding: 0.8rem 1.5rem;
    background-color: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    transition: all 0.2s ease;
    margin-top: 0.5rem;
}

.sales-quote-form button:hover {
    background-color: #6908C9;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(105, 8, 201, 0.15);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .sales-quote-container {
        padding: 1rem;
    }
    
    .sales-quote-form {
        padding: 1.5rem;
    }
}