.resources-container {
    background-color: white;
    min-height: 100vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
}

.resources-container h1 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
}

.resources-h3 {
    font-size: 18px;
    color: #504a5a;
    margin-bottom: 3rem;
    font-weight: normal;
    text-align: center;
}

.resources-section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

.resources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
}

.resource-card {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 2rem;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
}

.resource-card:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
    transform: translateY(-2px);
}

.resource-card h3 {
    font-size: 20px;
    color: #333;
    margin: 0;
    line-height: 1.4;
    padding-top: 0.5rem;
}

.resource-card p {
    font-size: 16px;
    color: #504a5a;
    margin: 0;
    line-height: 1.6;
    flex-grow: 1;
}

.resource-button {
    background: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    padding: 0.8rem 1.5rem;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    display: inline-block;
    text-align: center;
    margin-top: auto;
}

.resource-button:hover {
    background: #6908C9;
    color: white;
    transform: translateY(-1px);
}

@media (max-width: 768px) {
    .resources-container {
        padding: 1rem;
    }

    .resources-container h1 {
        font-size: 24px;
        margin-bottom: 0.5rem;
    }

    .resources-h3 {
        font-size: 16px;
        margin-bottom: 2rem;
    }

    .resources-section {
        padding: 0;
    }

    .resources-grid {
        gap: 1rem;
    }

    .resource-card {
        padding: 1.5rem;
    }
}
