.team-dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.team-dashboard-loading {
    text-align: center;
    padding: 2rem;
    font-size: 1.1rem;
    color: #666;
}

/* Team Goals Section */
.team-dashboard-goals-section {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
}

.team-dashboard-goals-section h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.3rem;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 0.75rem;
}

.no-goals-message {
    text-align: center;
    color: #888;
    font-style: italic;
    padding: 1rem 0;
}

.team-goals-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.team-goal-card {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
}

.team-goal-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-goal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.team-goal-header:hover {
    background-color: #f0f0f0;
}

.team-goal-header h4 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
    flex: 1;
}

.team-goal-progress-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-left: 1rem;
    min-width: 180px;
}

.team-goal-progress-bar {
    flex: 1;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
}

.team-goal-progress-fill {
    height: 100%;
    background-color: #4caf50;
    border-radius: 5px;
    transition: width 0.3s ease;
}

.team-goal-progress-text {
    font-size: 0.9rem;
    font-weight: 600;
    color: #555;
    min-width: 40px;
}

.edit-progress-button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.edit-progress-button:hover {
    background-color: #e0e0e0;
    color: #333;
}

.team-goal-details {
    padding: 1rem;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
}

.team-goal-details p {
    margin: 0 0 1rem 0;
    color: #555;
    line-height: 1.5;
}

.progress-edit-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    background-color: #f5f5f5;
    border-radius: 6px;
    margin-top: 0.5rem;
}

.progress-edit-container input[type="range"] {
    flex: 1;
}

.progress-edit-container span {
    font-weight: 600;
    color: #333;
    min-width: 40px;
}

.save-progress-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.save-progress-button:hover {
    background-color: #3d8b40;
}

/* Team Feed Section */
.team-dashboard-feed-section {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
}

.team-dashboard-feed-section h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.3rem;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 0.75rem;
}

.team-feed-post-form {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.team-feed-post-form textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    resize: vertical;
    font-family: inherit;
    font-size: 0.95rem;
}

.team-feed-post-form textarea:focus {
    outline: none;
    border-color: #4285f4;
    box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
}

.post-button {
    align-self: flex-end;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.6rem 1.2rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.2s ease;
}

.post-button:hover {
    background-color: #3367d6;
}

.no-posts-message {
    text-align: center;
    color: #888;
    font-style: italic;
    padding: 1rem 0;
}

.team-feed-posts {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.team-feed-post {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #f9f9f9;
}

.team-feed-post.progress_update {
    background-color: #e8f5e9;
    border-color: #c8e6c9;
}

.post-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
}

.post-author {
    font-weight: 600;
    color: #333;
}

.post-date {
    font-size: 0.85rem;
    color: #777;
}

.post-content {
    color: #444;
    line-height: 1.5;
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .team-dashboard-container {
        flex-direction: row;
    }
    
    .team-dashboard-goals-section,
    .team-dashboard-feed-section {
        flex: 1;
    }
}

@media (max-width: 767px) {
    .team-goal-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
    }
    
    .team-goal-progress-container {
        width: 100%;
        margin-left: 0;
    }
} 