/* ======================
   Dashboard Layout & Container Styles 
   ====================== */
.dashboard-container {
    font-family: 'Literata', serif;
    background-color: #f9f9fb;
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

.dashboard-header-container {
    background-color: white;
    padding: 1.5rem 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.dashboard-back-button {
    margin-right: 1.5rem;
}

.dashboard-header-content {
    flex: 1;
}

.dashboard-title {
    font-size: 1.8rem;
    color: #333;
    margin: 0 0 0.25rem 0;
    font-weight: 600;
}

.dashboard-welcome {
    color: #6908C9;
    margin: 0;
    font-size: 1.1rem;
}

.dashboard-content {
    padding: 0 2rem 2rem;
    max-width: 1400px;
    margin: 0 auto;
}

.dashboard-main {
    display: flex;
    gap: 1.5rem;
}

.dashboard-column {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.dashboard-column-main {
    flex: 2;
}

.dashboard-column-side {
    flex: 1;
}

/* ======================
   Dashboard Card Styles 
   ====================== */
.dashboard-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: all 0.3s ease;
}

.dashboard-card:hover {
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.1);
    transform: translateY(-3px);
}

.dashboard-card-header {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid #f0f0f0;
}

.dashboard-card-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
    color: #333;
    font-size: 1.25rem;
    font-weight: 600;
}

.dashboard-card-icon {
    color: #6908C9;
}

.dashboard-card-content {
    padding: 1.5rem;
}

/* ======================
   Progress Overview Styles 
   ====================== */
.dashboard-progress-overview {
    display: flex;
    gap: 2rem;
}

.dashboard-progress-circle-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-progress-circle {
    width: 180px;
    height: 180px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.dashboard-circular-chart {
    position: absolute;
    width: 100%;
    height: 100%;
}

.dashboard-circle-bg {
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 3.8;
}

.dashboard-circle-progress {
    fill: none;
    stroke: #6908C9;
    stroke-width: 3.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.5s ease;
}

.dashboard-percentage {
    fill: #6908C9;
    font-size: 0.5em;
    text-anchor: middle;
    font-weight: bold;
    dominant-baseline: middle;
}

.dashboard-progress-label {
    position: absolute;
    bottom: -25px;
    color: #504a5a;
    font-size: 0.9rem;
    text-align: center;
    width: 100%;
    white-space: nowrap;
}

.dashboard-progress-stats-container {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dashboard-progress-stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.dashboard-stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background: #f9f9fb;
    border-radius: 8px;
    min-width: 100px;
}

.dashboard-stat-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: #6908C9;
    margin-bottom: 0.25rem;
}

.dashboard-stat-label {
    font-size: 0.85rem;
    color: #504a5a;
    text-align: center;
}

/* ======================
   Activity Styles 
   ====================== */
.dashboard-activity-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dashboard-activity-item {
    background: #f9f9fb;
    border-radius: 8px;
    padding: 1.25rem;
    transition: all 0.2s ease;
}

.dashboard-activity-item:hover {
    background: #f5f0ff;
}

.dashboard-activity-progress {
    height: 6px;
    background: #e0e0e0;
    border-radius: 3px;
    margin-bottom: 1rem;
    overflow: hidden;
}

.dashboard-activity-progress-bar {
    height: 100%;
    background: #6908C9;
    border-radius: 3px;
    transition: width 0.3s ease;
}

.dashboard-activity-content {
    display: flex;
    flex-direction: column;
}

.dashboard-activity-title {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin: 0 0 0.5rem;
}

.dashboard-activity-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-activity-progress-text {
    font-size: 0.9rem;
    color: #6908C9;
}

.dashboard-activity-verified {
    font-size: 0.8rem;
    background: #4CAF50;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.dashboard-no-activity {
    text-align: center;
    padding: 2rem 0;
    color: #888;
}

.dashboard-no-activity-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #dcdcdc;
}

/* ======================
   Streak Styles 
   ====================== */
.dashboard-streak-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-streak-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
}

.badge-icon {
    font-size: 3.5rem;
    margin-bottom: 0.75rem;
    color: #6908C9;
}

.badge-icon.gold {
    color: #FFD700;
}

.badge-icon.silver {
    color: #C0C0C0;
}

.badge-icon.bronze {
    color: #CD7F32;
}

.dashboard-streak-badge-name {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
}

.dashboard-streak-stats {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
    margin-bottom: 1.5rem;
}

.dashboard-last-checkin {
    background: #f9f9fb;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    width: 100%;
    text-align: center;
}

.dashboard-last-checkin-label {
    font-size: 0.9rem;
    color: #504a5a;
    margin-right: 0.5rem;
}

.dashboard-last-checkin-value {
    font-weight: 600;
    color: #333;
}

/* ======================
   Achievements Styles 
   ====================== */
.dashboard-achievements-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 400px;
    overflow-y: auto;
}

.dashboard-achievement-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 8px;
    background: #f9f9fb;
    transition: all 0.2s ease;
    border-left: 4px solid #6908C9;
    text-decoration: none;
}

.dashboard-achievement-item:hover {
    transform: translateY(-3px);
    background: #f5f0ff;
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.1);
}

.dashboard-achievement-item.progress {
    border-left-color: #6908C9;
}

.dashboard-achievement-item.completion {
    border-left-color: #4CAF50;
}

.dashboard-achievement-item.verification {
    border-left-color: #2196F3;
}

.dashboard-achievement-icon {
    font-size: 1.75rem;
    margin-right: 1rem;
    color: #6908C9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-achievement-content {
    flex: 1;
}

.dashboard-achievement-title {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    margin: 0 0 0.25rem;
}

.dashboard-achievement-description {
    font-size: 0.9rem;
    color: #504a5a;
    margin: 0 0 0.5rem;
}

.dashboard-achievement-date {
    font-size: 0.8rem;
    color: #888;
}

.dashboard-no-achievements {
    text-align: center;
    padding: 2rem 0;
    color: #888;
}

.dashboard-no-achievements-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #dcdcdc;
}

/* ======================
   Stats Grid Styles 
   ====================== */
.dashboard-stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.dashboard-stat-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9f9fb;
    border-radius: 8px;
    padding: 1.25rem 1rem;
    transition: all 0.2s ease;
}

.dashboard-stat-card:hover {
    background: #f5f0ff;
    transform: translateY(-3px);
}

/* ======================
   Quick Links Styles 
   ====================== */
.dashboard-quick-links {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.dashboard-quick-link-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    background: #f9f9fb;
    border: none;
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
    color: #333;
    font-family: 'Literata', serif;
    font-size: 1rem;
}

.dashboard-quick-link-button:hover {
    background: #f5f0ff;
    transform: translateX(5px);
}

.dashboard-quick-link-icon {
    color: #6908C9;
    font-size: 1.25rem;
}

/* ======================
   Action Button Styles 
   ====================== */
.dashboard-action-button {
    background: none;
    border: 2px solid #6908C9;
    color: #6908C9;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-family: 'Literata', serif;
    font-weight: 500;
    width: 100%;
}

.dashboard-action-button:hover {
    background-color: #6908C9;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(105, 8, 201, 0.15);
}

/* ======================
   Loading Styles 
   ====================== */
.dashboard-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    color: #6908C9;
}

.dashboard-loading-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(105, 8, 201, 0.1);
    border-radius: 50%;
    border-top-color: #6908C9;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 1rem;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* ======================
   Media Queries 
   ====================== */
@media screen and (max-width: 1200px) {
    .dashboard-stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 992px) {
    .dashboard-main {
        flex-direction: column;
    }
    
    .dashboard-column-main,
    .dashboard-column-side {
        flex: 1;
    }
    
    .dashboard-progress-overview {
        flex-direction: column;
        align-items: center;
    }
    
    .dashboard-progress-circle-container {
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .dashboard-header-container {
        padding: 1rem;
    }
    
    .dashboard-content {
        padding: 0 1rem 1rem;
    }
    
    .dashboard-title {
        font-size: 1.5rem;
    }
    
    .dashboard-welcome {
        font-size: 1rem;
    }
    
    .dashboard-card-header {
        padding: 1rem;
    }
    
    .dashboard-card-content {
        padding: 1rem;
    }
    
    .dashboard-stats-grid {
        grid-template-columns: 1fr;
    }
    
    .dashboard-streak-stats {
        flex-direction: column;
        gap: 1rem;
    }
    
    .dashboard-stat-item {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .dashboard-progress-circle {
        width: 150px;
        height: 150px;
    }
    
    .dashboard-card-title {
        font-size: 1.1rem;
    }
    
    .dashboard-stat-value {
        font-size: 1.5rem;
    }
}

/* Preserve existing styles for backward compatibility */
.dashboard-section-icon {
    margin-right: 0.5rem;
    color: #6908C9;
}

.dashboard-goals-preview {
    background: #f8f5fd;
    border-radius: 8px;
    padding: 1.5rem;
    border: 1px solid #e0d5f0;
    margin-bottom: 1.5rem;
}

.dashboard-goals-preview h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #504a5a;
    font-size: 18px;
    border-bottom: 1px solid #e0d5f0;
    padding-bottom: 0.5rem;
}

.dashboard-goals-stats {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
}

/* Scrollbar styling for better UX */
.dashboard-achievements-list::-webkit-scrollbar {
    width: 6px;
}

.dashboard-achievements-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.dashboard-achievements-list::-webkit-scrollbar-thumb {
    background: #d1c4e9;
    border-radius: 10px;
}

.dashboard-achievements-list::-webkit-scrollbar-thumb:hover {
    background: #6908C9;
}

/* Add these styles for the check-in feature */

/* Check-ins Card */
.dashboard-checkins-card {
    margin-bottom: 20px;
}

.dashboard-upcoming-checkins {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.dashboard-checkin-item {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    transition: transform 0.2s;
}

.dashboard-checkin-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.dashboard-checkin-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #e3f2fd;
    color: #1976d2;
    border-radius: 50%;
    margin-right: 15px;
    flex-shrink: 0;
}

.dashboard-checkin-content {
    flex: 1;
}

.dashboard-checkin-date {
    font-weight: 500;
    margin-bottom: 5px;
}

.dashboard-checkin-notes {
    font-size: 0.9rem;
    color: #666;
}

.dashboard-no-checkins {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    color: #757575;
}

.dashboard-no-checkins-icon {
    font-size: 2rem;
    margin-bottom: 10px;
    opacity: 0.7;
}

/* Recent Check-ins in Streak Card */
.dashboard-recent-checkins {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #eee;
}

.dashboard-recent-checkins h4 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #555;
}

.dashboard-recent-checkin-item {
    padding: 10px;
    margin-bottom: 8px;
    background-color: #f9f9f9;
    border-radius: 6px;
    font-size: 0.9rem;
}

.dashboard-recent-checkin-date {
    font-weight: 500;
    margin-bottom: 3px;
}

.dashboard-recent-checkin-notes {
    color: #666;
    font-size: 0.85rem;
} 