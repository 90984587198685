.message-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fee2e2;
  color: #b91c1c;
  padding: 12px;
  text-align: center;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f87171;
}

.close-button {
  background: none;
  border: none;
  color: #b91c1c;
  font-weight: bold;
  margin-left: 16px;
  cursor: pointer;
}

/* Add these new classes for the login page display */
.session-timeout-banner {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fee2e2;
  color: #b91c1c;
  border: 1px solid #f87171;
  border-radius: 4px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 500px;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  animation: slideDown 0.5s ease-out;
}

.session-timeout-banner-close {
  background: none;
  border: none;
  color: #b91c1c;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}

@keyframes slideDown {
  from {
    top: -50px;
    opacity: 0;
  }
  to {
    top: 20px;
    opacity: 1;
  }
}
