/* ========================================
   ManagerMaster.js Styles
   ======================================== */
.manager-goal-set-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.manager-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: white;
}

.manager-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.goal-setting-inactive-message {
    text-align: center;
    padding: 2rem;
    margin: 2rem auto;
    max-width: 600px;
}

/* Loading States */
.manager-loading-overlay {
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manager-loading-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.manager-loading-text {
    margin-top: 20px;
    color: black;
    font-size: 1.2rem;
    font-weight: 500;
}

/* ========================================
   Common Styles (Used across multiple steps)
   ======================================== */
.manager-goal-set-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.manager-goal-set-title {
    color: black;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.manager-goal-set-subtitle {
    color: #504a5a;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2rem;
}

.manager-goal-set-button-container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    gap: 20px;
    flex-wrap: wrap;
}

.manager-goal-set-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    min-width: auto;
    width: auto;
}

.manager-goal-set-link:hover {
    color: #d1c4e9;
}

.manager-goal-set-submit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.manager-goal-set-submit-link:hover {
    color: #d1c4e9;
}

.manager-goal-set-submit-link:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.manager-goal-set-description {
    color: #504a5a;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2rem;
    max-width: 750px;
    width: 100%;
}

/* Progress Bar Styles */
.manager-step-progress {
    width: 100%;
    max-width: 800px;
    margin: 30px auto;
    padding: 0 20px;
}

.manager-step-progress-bar {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
}

.manager-step-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 4px;
    transition: width 0.3s ease;
}

.manager-step-progress-text {
    font-size: 14px;
    color: #666;
    text-align: right;
}

/* ========================================
   ManagerStep1.js Styles
   ======================================== */
.manager-goal-set-back-wrapper {
    align-self: flex-start;
}

.manager-mission-card {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 2rem;
    margin: 1rem auto;
    width: 100%;
    max-width: 750px;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    transition: all 0.2s ease;
}

.manager-mission-card:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.manager-mission-icon {
    font-size: 24px;
    color: #6908C9;
}

.manager-goal-review-mission {
    font-size: 16px;
    color: #504a5a;
    flex: 1;
}

.manager-goals-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    width: 100%;
    max-width: 750px;
    margin: 1rem auto;
}

.manager-goal-card {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;
    transition: all 0.2s ease;
}

.manager-goal-card:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

/* ========================================
   ManagerStep2.js Styles
   ======================================== */
.manager-team-context-form {
    width: 100%;
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.manager-team-context-form-section {
    margin-bottom: 20px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    border: none;
    box-shadow: none;
}

.manager-team-context-form-section-title {
    color: black;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    border-bottom: none;
    padding-bottom: 0;
    text-align: center;
    width: 100%;
}

.manager-team-context-label {
    font-size: 16px;
    color: #504a5a;
    margin-bottom: 10px;
    align-self: flex-start;
    margin-left: 10%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    width: 80%;
    max-width: 700px;
}

.manager-tooltip-icon {
    color: #504a5a;
    cursor: help;
    font-size: 14px;
}

.manager-character-count {
    font-size: 12px;
    color: #6c757d;
    position: absolute;
    right: 0;
    white-space: nowrap;
}

.manager-team-size-selector,
.manager-work-style-selector {
    display: flex;
    width: 80%;
    max-width: 700px;
    margin: 1rem 0 2rem;
    gap: 1rem;
    justify-content: space-between;
    align-self: center;
}

.manager-team-context-input {
    width: 80%;
    max-width: 700px;
    min-height: 150px;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    margin: 0.5rem auto 2rem;
    display: block;
}

.manager-size-option,
.manager-style-option {
    flex: 1;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    height: 100px;
    justify-content: center;
}

.manager-size-option:hover,
.manager-style-option:hover {
    border-color: #6908C9;
    background: #eddef8;
}

.manager-size-option.selected,
.manager-style-option.selected {
    background: #f9f5ff;
    color: #333;
    border-color: #6908C9;
    box-shadow: 0 0 0 2px rgba(105, 8, 201, 0.2);
}

.manager-style-option svg {
    font-size: 24px;
    margin-bottom: 8px;
    color: #6908C9;
}

.manager-style-option.selected svg {
    color: #6908C9;
}

.manager-style-option span {
    display: block;
    font-size: 14px;
}

.manager-challenges-grid {
    width: 80%;
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    margin: 1rem 0 2rem;
    align-self: center;
}

.manager-challenge-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    user-select: none;
}

.manager-challenge-option:hover {
    border-color: #6908C9;
    background: #eddef8;
}

/* Confirmation view styles */
.manager-confirmation-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.manager-confirmation-section {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    transition: all 0.2s ease;
}

.manager-confirmation-section:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.manager-confirmation-section h5 {
    color: #6908C9;
    font-size: 18px;
    margin-bottom: 1rem;
    font-weight: 500;
}

.manager-confirmation-item {
    margin: 0.8rem 0;
    padding: 0.5rem;
    border-radius: 4px;
    background: #faf7fd;
    line-height: 1.5;
}

.manager-confirmation-item strong {
    color: #504a5a;
    margin-right: 8px;
    font-weight: 500;
}

.manager-confirmation-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.manager-confirmation-section ul li {
    margin: 0.5rem 0;
    padding: 0.5rem;
    background: #faf7fd;
    border-radius: 4px;
    color: #504a5a;
}

/* ========================================
   ManagerStep3.js Styles
   ======================================== */
.manager-goal-actions {
    width: 100%;
    max-width: 750px;
    display: flex;
    gap: 2rem;
    margin: 10px auto 3rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;
}

.manager-add-goal-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem 1.8rem;
    background: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 180px;
    max-width: 250px;
    width: auto;
}

.manager-add-goal-button:hover {
    background: #eddef8;
    transform: translateY(-1px);
}

.manager-goals-container {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

.manager-goal-item {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}

.manager-goal-number {
    font-size: 20px;
    font-weight: 500;
    color: #6908C9;
    min-width: 30px;
    text-align: center;
    margin-top: 1rem;
    padding-left: 0;
}

.manager-goal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: calc(100% - 45px);
    box-sizing: border-box;
}

.manager-goal-input {
    width: 100%;
    min-height: 150px;
    padding: 1.2rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    box-sizing: border-box;
    margin: 0;
}

.manager-goal-input:read-only {
    background-color: #f0f0f0;
    border-color: #dcdcdc;
    cursor: default;
}

/* Fix alignment of action buttons */
.manager-goal-item .manager-goal-actions {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    margin: 10px 0 0 0;
    padding: 0;
    justify-content: flex-start;
}

.manager-action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.7rem 1.2rem;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 0;
}

.manager-action-button.confirm {
    background: #6908C9;
    color: white;
    border: none;
}

.manager-action-button.edit {
    background: white;
    color: #6908C9;
    border: 1px solid #6908C9;
}

.manager-action-button.delete {
    background: white;
    color: #dc3545;
    border: 1px solid #dc3545;
}

.manager-action-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

/* ========================================
   ManagerStep4.js Styles
   ======================================== */
.manager-goals-review-container {
    width: 90%;
    max-width: 750px;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.manager-goals-edit-list,
.manager-goals-display-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.manager-goal-review-item {
    width: 100%;
    display: flex;
    gap: 1rem;
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1.5rem;
    transition: all 0.2s ease;
    box-sizing: border-box;
}

.manager-goal-review-item:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.manager-goal-review-item.editing {
    border-color: #6908C9;
    background-color: #faf7fd;
}

.manager-goal-review-content {
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
    color: #504a5a;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding-right: 10px;
}

.manager-goal-review-input {
    width: 100%;
    min-height: 150px;
    padding: 1rem;
    border: 1px solid #6908C9;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    box-sizing: border-box;
    margin: 0;
    background-color: white;
}

.manager-edit-goals-button,
.manager-edit-done-button {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 1.5rem auto;
}

.manager-edit-goals-button {
    background: white;
    color: #6908C9;
    border: 2px solid #6908C9;
}

.manager-edit-done-button {
    background: #6908C9;
    color: white;
    border: none;
}

.manager-edit-goals-button:hover,
.manager-edit-done-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.manager-no-goals-message {
    text-align: center;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 8px;
    color: #6c757d;
}

/* ========================================
   Media Queries
   ======================================== */
@media screen and (max-width: 768px) {
    .manager-team-context-form {
        padding: 10px;
    }

    .manager-team-size-selector,
    .manager-work-style-selector,
    .manager-challenges-grid {
        width: 90%;
    }

    .manager-goal-actions {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }

    .manager-add-goal-button {
        width: auto;
        min-width: 160px;
    }

    .manager-goal-set-container {
        padding: 1rem;
    }

    .manager-mission-card,
    .manager-goals-grid,
    .manager-goals-container,
    .manager-goals-review-container {
        width: 95%;
    }
    
    .manager-team-context-label {
        width: 90%;
        margin-left: 5%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .manager-character-count {
        position: static;
        margin-left: auto;
    }
    
    .manager-team-context-input {
        width: 90%;
    }
}

@media screen and (max-width: 480px) {
    .manager-goal-set-title {
        font-size: 24px;
    }

    .manager-team-context-form-section-title {
        font-size: 18px;
    }

    .manager-team-size-selector,
    .manager-work-style-selector,
    .manager-challenges-grid {
        grid-template-columns: 1fr;
    }

    .manager-goal-set-link,
    .manager-goal-set-submit-link {
        font-size: 16px;
    }

    .manager-loading-text {
        font-size: 1rem;
        padding: 0 15px;
    }
}
