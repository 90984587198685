.login-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.login-body h1 {
    margin-top: 20px;
    margin-left: 5px;
    font-size: 30px;
    align-self: center;
}

.login-body p {
    margin-left: 5px;
    font-size: 18px;
    align-self: center;
    margin-top: 10px;
}

.login-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.login-box {
    width: 352px;
    height: 600px;
    padding: 20px;
    margin: 70px auto;
    background-color: #f9f9f9; /* Optional: Background color */
    cursor: default; /* Ensure the box itself is not clickable */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px; /* Added rounded corners */
    transition: background-color .3s, color .3s;
}

.login-h3-container {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    width: 100%; /* Ensure it takes full width */
    color: black;
    margin-top: 50px;
}

.login-h3-container a {
    color: #6908C9;
    text-decoration: none;
}

.login-h3-container a:hover {
    color: #d1c4e9;
}

.login-input {
    width: 100%;
    min-width: 250px;
    padding: 0 12px;
    line-height: 50px;
    font-size: 18px;
    font-family: 'Literata', serif;
    align-self: center;
}

.login-button {
    background-color: #6908C9; /* Button background color */
    color: white; /* Button text color */
    border: 1px solid black;
    width: 150px; /* Set a fixed width for the button */
    height: 50px; /* Set a fixed height for the button */
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    flex-shrink: 0; /* Prevent the button from shrinking */
}

.login-button:hover {
    background-color: #d1c4e9; /* Darker shade on hover */
    border: 1px solid black;

   }

.login-button:hover a {
    color: white;
    text-decoration: none;
}

.login-button a {
    color: white; /* Inherit button text color */
    text-decoration: none; /* Remove underline */
    cursor: pointer; /* Ensure the cursor is a pointer */
}

.login-sso-button {
    background-color: transparent; /* Remove background color */
    color: #6908C9; /* Link color */
    border: none; /* Remove border */
    width: auto; /* Allow width to adjust */
    height: auto; /* Allow height to adjust */
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none; /* Ensure no underline */
    flex-shrink: 0; /* Prevent the button from shrinking */
}

.login-sso-button:hover {
    color: #d1c4e9; /* Change color on hover */
}

.login-sso-button:hover a {
    color: white;
    text-decoration: none;
}

.login-sso-button a {
    color: white; /* Inherit button text color */
    text-decoration: none; /* Remove underline */
    cursor: pointer; /* Ensure the cursor is a pointer */
}

.login-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.login-sso-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.forgot-password-container {
    margin-top: 20px;
    width: 100%;
    text-align: center; /* Center the text */
    align-self: center;  /* Center the container */
}

.forgot-password-link {
    margin: 0;
    font-size: 18px;
    color: #6908C9;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Remove text-align: left; to inherit the center alignment */
}

.forgot-password-link:hover {
    color: #d1c4e9;
}

.login-input::placeholder {
    color: #999; /* Placeholder text color */
    font-family: 'Literata', serif;
    font-size: 18px;
    text-align: left;
}

.or-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 0; /* Adjust spacing as needed */
}

.or-line {
    flex: 1;
    border: 0;
    border-top: 1px solid #ccc; /* Adjust color as needed */
    margin: 0 10px; /* Space between line and text */
}

@media (max-width: 768px) {
    .login-box {
        width: 100%;
        height: 100%;
        margin: 0;
        margin-top: 40px;
        padding: 0;
    }
}

.login-error-message {
    background-color: #fee2e2;
    color: #b91c1c;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90%;
    text-align: center;
    font-size: 14px;
}

/* Also add some styling for the SessionTimeoutMessage if displayed in the login page */
.session-timeout-banner {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-width: 500px;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
    from {
        top: -50px;
        opacity: 0;
    }
    to {
        top: 20px;
        opacity: 1;
    }
}

.session-timeout-banner-close {
    background: none;
    border: none;
    color: #721c24;
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
}