.ep-portal {
    display: flex;
    font-family: 'Literata', serif;
    background-color: white;
    min-height: 100vh;
}

.ep-portal-sidebar {
    width: 250px;
    background-color: #f8f8f8;
    padding: 2rem 1rem;
    height: 100vh;
    border-right: 1px solid #dcdcdc;
    transition: all 0.3s ease;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
}

.ep-portal-sidebar-button {
    font-family: 'Literata', serif;
    font-size: 16px;
    border: none;
    background-color: transparent;
    display: flex;
    padding: 1rem 1.2rem;
    text-decoration: none;
    color: #504a5a;
    margin-bottom: 0.8rem;
    width: 100%;
    border-radius: 8px;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    justify-content: flex-start;
    text-align: left;
}

.ep-portal-sidebar-button:hover {
    background-color: #eddef8;
    color: #6908C9;
    text-decoration: none;
    transform: translateX(3px);
}

.ep-portal-sidebar-button:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: #6908C9;
    transform: scaleY(0);
    transition: transform 0.2s ease;
}

.ep-portal-sidebar-button:hover:before {
    transform: scaleY(1);
}

.ep-portal-content {
    flex-grow: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.ep-portal-menu-button {
    display: none;
    font-size: 18px;
    font-family: 'Literata', serif;
    cursor: pointer;
    margin: 10px;
    background: none;
    border: none;
    color: #6908C9;
    transition: color 0.3s;
}

.ep-portal-menu-button:hover {
    color: #d1c4e9;
}

.ep-portal-sidebar.mobile {
    display: none;
    position: fixed;
    top: 0;
    left: -250px;
    width: 200px;
    height: 100%;
    background-color: #f4f4f4;
    transition: left 0.3s ease;
    z-index: 1000;
}

.ep-portal-sidebar.mobile.open {
    left: 0;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .ep-portal-menu-button {
        display: flex;
        margin-top: 20px;
    }

    .ep-portal-sidebar {
        display: none;
    }

    .ep-portal-sidebar.mobile {
        display: block;
    }

    .ep-portal-sidebar-button, 
    .ep-portal-sidebar.mobile .ep-portal-sidebar-button {
        justify-content: flex-start;
        text-align: left;
    }
}

/* Primary purple buttons for portal actions */
.ep-goal-setting-button {
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.ep-goal-setting-button:hover {
    background-color: #5607a3;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.1);
}

/* White buttons specifically for dashboard cards */
.ep-dashboard-button {
    background-color: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    padding: 10px 18px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.ep-dashboard-button:hover {
    background-color: #eddef8;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.1);
}

.ep-goal-setting-button-container {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

/* Content Section Styles */
.portal-section {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
}

.portal-section:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.08);
    transform: translateY(-2px);
}

.portal-section h2 {
    color: #6908C9;
    font-size: 24px;
    margin-bottom: 1rem;
    font-weight: 600;
}

.portal-section p {
    color: #504a5a;
    font-size: 16px;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

/* Active sidebar item styling */
.ep-portal-sidebar-button.active {
    background-color: #eddef8;
    color: #6908C9;
    font-weight: 500;
}

.ep-portal-sidebar-button.active:before {
    transform: scaleY(1);
}

/* Disabled sidebar button styling */
.ep-portal-sidebar-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #f0f0f0;
    color: #999;
}

.ep-portal-sidebar-button.disabled:hover {
    background-color: #f0f0f0;
    color: #999;
    transform: none;
    box-shadow: none;
}

.ep-portal-sidebar-button.disabled:before {
    display: none;
}

/* Employee Dashboard Grid Layout */
.ep-dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin: 1.5rem 0;
}

/* For mobile screens */
@media screen and (max-width: 768px) {
    .ep-dashboard-grid {
        grid-template-columns: 1fr;
    }
}

/* Dashboard Card Styles */
.ep-dashboard-card {
    background: #f8f5fd;
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid #e0d5f0;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.ep-dashboard-card:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.1);
    transform: translateY(-3px);
}

.ep-dashboard-card-header {
    margin-bottom: 1.2rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #e0d5f0;
}

.ep-dashboard-card-header h3 {
    color: #504a5a;
    font-size: 18px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
}

.ep-dashboard-card .card-icon {
    color: #6908C9;
}

.ep-dashboard-buttons {
    margin-top: auto;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}

.ep-dashboard-loading {
    text-align: center;
    padding: 2rem 0;
    color: #888;
    font-style: italic;
}

.ep-no-data-message {
    text-align: center;
    padding: 2rem 0;
    color: #888;
    font-style: italic;
}

/* Stats Grid */
.ep-stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
}

.ep-stat-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.ep-stat-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ep-stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #6908C9;
}

.ep-stat-label {
    font-size: 14px;
    color: #504a5a;
    margin-top: 0.5rem;
    text-align: center;
}

/* Goals Stats */
.ep-goals-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

/* Check-ins Styles */
.ep-checkins-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.ep-checkin-item {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.ep-checkin-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ep-checkin-date {
    font-weight: 500;
    margin-bottom: 0.3rem;
    color: #6908C9;
}

.ep-checkin-manager {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

.ep-checkin-notes {
    padding-top: 0.5rem;
    border-top: 1px dashed #e0e0e0;
    font-size: 0.9rem;
    font-style: italic;
    color: #666;
}

/* Goals Styles */
.ep-goals-summary {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.ep-goals-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.ep-goal-item {
    background: white;
    border-radius: 8px;
    padding: 1.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.ep-goal-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ep-goal-summary {
    margin-bottom: 1rem;
    font-weight: 500;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    line-height: 1.4;
    color: #333;
}

/* Add styles for the manager name display */
.ep-goal-manager {
    font-size: 0.85rem;
    font-style: italic;
    color: #666;
    margin-bottom: 0.75rem;
}

.ep-goal-progress-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin-top: 0.75rem;
}

.ep-goal-progress-bar {
    flex: 1;
    height: 10px;
    background-color: #eeeeee;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.ep-goal-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 5px;
    transition: width 0.5s ease;
}

.ep-goal-progress-text {
    font-size: 0.9rem;
    font-weight: 600;
    color: #6908C9;
    min-width: 45px;
    text-align: right;
}

/* Achievements Styles */
.ep-achievements-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.ep-achievement-item {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    gap: 15px;
    align-items: flex-start;
    transition: transform 0.2s ease;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.ep-achievement-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ep-achievement-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: #f0e6fa;
    border-radius: 50%;
    padding: 8px;
    flex-shrink: 0;
}

.achievement-icon-complete {
    color: #6908C9;
    font-size: 20px;
}

.achievement-icon-streak {
    color: #6908C9;
    font-size: 20px;
}

.achievement-icon-progress {
    color: #6908C9;
    font-size: 20px;
}

.ep-achievement-content {
    flex-grow: 1;
    max-width: calc(100% - 51px); /* 36px icon width + 15px gap */
    overflow: hidden;
}

.ep-achievement-title,
.ep-achievement-description {
    word-break: break-word;
    overflow-wrap: break-word;
}

.ep-achievement-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
}

.ep-achievement-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
}

.ep-achievement-date {
    font-size: 12px;
    color: #999;
}

/* Additional styles for the dashboard cards */
.ep-dashboard-card.team-goals,
.ep-dashboard-card.personal-goals {
    background-color: #f9f6ff;
}

.ep-dashboard-card .ep-goals-list {
    margin-bottom: 1.5rem;
}

/* Archived goals display */
.ep-archived-goals-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.ep-archived-section {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1.5rem;
}

.ep-archived-section h3 {
    color: #6908C9;
    margin-bottom: 1rem;
}

.ep-archived-goals-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

.ep-archived-goal-card {
    background-color: #f9f6ff;
    border: 1px solid #e0d5f0;
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.2s ease;
}

.ep-archived-goal-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
}

.ep-archived-goal-content h4 {
    color: #504a5a;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.ep-archived-goal-progress {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.8rem 0;
}

.ep-archived-progress-bar {
    flex: 1;
    height: 8px;
    background-color: #eeeeee;
    border-radius: 4px;
    overflow: hidden;
}

.ep-archived-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 4px;
}

.ep-archived-progress-text {
    font-size: 0.85rem;
    font-weight: 600;
    color: #6908C9;
    min-width: 40px;
    text-align: right;
}

.ep-archived-date {
    font-size: 0.75rem;
    color: #888;
    margin-top: 0.5rem;
    font-style: italic;
}

.ep-no-archived {
    color: #888;
    font-style: italic;
    text-align: center;
    padding: 1rem;
}

.ep-loading {
    text-align: center;
    padding: 2rem;
    color: #888;
    font-style: italic;
}

/* Unarchive button styling */
.ep-archived-actions {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.ep-unarchive-button {
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.ep-unarchive-button:hover {
    background-color: #5607A3;
    transform: translateY(-2px);
}

/* Achievements Summary Styles */
.ep-achievements-summary {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 1.5rem 0;
}

.ep-achievements-overview {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.ep-achievements-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
}

.ep-action-buttons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

/* Additional achievement styles for the achievement page */
.achievement-category {
    margin-bottom: 2.5rem;
}

.achievement-category-title {
    color: #6908C9;
    font-size: 20px;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #eddef8;
}

.locked-achievement {
    opacity: 0.5;
    filter: grayscale(90%);
    position: relative;
}

.locked-achievement:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    z-index: 1;
}

.locked-achievement:after {
    content: '🔒';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    z-index: 2;
}

.achievement-progress {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #6908C9;
}

.achievement-progress-bar {
    height: 6px;
    background-color: #eeeeee;
    border-radius: 3px;
    overflow: hidden;
    margin-top: 0.3rem;
}

.achievement-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 3px;
}

/* Achievement badges for profile display */
.achievement-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0e6fa;
    margin-right: 8px;
    box-shadow: 0 2px 4px rgba(105, 8, 201, 0.2);
    position: relative;
    transition: transform 0.2s ease;
}

.achievement-badge:hover {
    transform: scale(1.1);
}

.achievement-badge-icon {
    color: #6908C9;
    font-size: 20px;
}

.achievement-tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    background-color: #504a5a;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    z-index: 10;
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    pointer-events: none;
}

.achievement-badge:hover .achievement-tooltip {
    visibility: visible;
    opacity: 1;
}