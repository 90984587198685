.about-body {
    background-color: white;
    min-height: 100vh;
    margin: 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.about-header {
    text-align: center;
    margin-bottom: 3rem;
    width: 100%;
}

.about-header h1 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.about-section {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 2rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    transition: transform 0.2s ease;
    width: 70%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

/* Keep your existing background image styles but update the container */
.mission {
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to bottom, rgba(237, 222, 248, 0.7) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(237, 222, 248, 0.7) 100%),
                      url("https://images.unsplash.com/photo-1522124624696-7ea32eb9592c?q=1000&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-blend-mode: overlay;
    background-color: #eddef8;
    border: 1px solid #dcdcdc;
}

.story {
    background-color: white;
    border: 1px solid #dcdcdc;
}

.call-to-action {
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to bottom, rgba(237, 222, 248, 0.7) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(237, 222, 248, 0.7) 100%),
    url("https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?q=80&w=1813&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-blend-mode: overlay;
    background-color: #eddef8;
    border: 1px solid #dcdcdc;
}

.about-section:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.about-section-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: 500;
}

.about-section-text {
    max-width: 800px;
    font-size: 16px;
    line-height: 1.8;
    color: #504a5a;
    text-align: center;
    margin: 0 auto 1.5rem;
    padding: 0 1rem;
}

.about-section-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
}

.about-section-list-item {
    font-size: 16px;
    color: #504a5a;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
    line-height: 1.6;
}

.about-section-list-item:before {
    content: "\2605";
    position: absolute;
    left: 0;
    color: #6908C9;
}

.about-sign-up-button {
    background: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    padding: 0.8rem 1.5rem;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 2rem;
}

.about-sign-up-button:hover {
    background: #6908C9;
    color: white;
    transform: translateY(-1px);
}

@media (max-width: 768px) {
    .about-body {
        padding: 1rem;
    }

    .about-section {
        padding: 2rem 1rem;
        min-height: auto;
    }

    .about-section-header {
        font-size: 20px;
    }

    .about-section-text {
        font-size: 15px;
        padding: 0;
    }

    .about-section-list-item {
        font-size: 15px;
    }

    /* Keep your existing mobile background image styles */
    .mission, .call-to-action {
        background-repeat: no-repeat;
    }
}