.astellyst-ai-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    background-color: #eddef8;
    color: #6908C9;
    font-size: 16px;
    font-family: "Literata", serif;
    border-radius: 8px;
    border: 2px solid #6908C9;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    min-width: 180px;
    max-width: 250px;
    width: auto;
}

.astellyst-ai-button:hover {
    background-color: #d1c4e9;
    color: #6908C9;
    transform: translateY(-1px);
}

.astellyst-ai-button.generating {
    opacity: 0.8;
    cursor: not-allowed;
}

/* Add Astellyst AI branding */
.astellyst-ai-button::before {
    content: "Astellyst AI";
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #6908C9;
    color: white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: bold;
    letter-spacing: 0.5px;
}

/* eddef8 */