/* ======================
   Layout & Container Styles 
   ====================== */
.manager-progress-body {
    background-color: white;
    min-height: 100vh;
    padding: 2rem;
}

.page-content {
    max-width: 800px; /* Standardize to 800px for consistency */
    margin: 0 auto;
    width: 100%;
}

.manager-progress-content {
    max-width: 800px; /* This was already 800px */
    margin: 0 auto;
    width: 100%;
}

.manager-progress-back-wrapper {
    align-self: flex-start;
    width: fit-content;
}

/* ======================
   Header Styles 
   ====================== */
.manager-progress-header {
    text-align: center;
    margin-bottom: 2.5rem;
}

.manager-progress-header-text {
    font-size: 28px;
    color: black;
    margin-bottom: 0.5rem;
    text-align: center;
}

.manager-progress-header-subtext {
    color: #504a5a;
    font-size: 18px;
    font-family: 'Literata', serif;
    margin-bottom: 1.5rem;
}

/* ======================
   Goal Card Styles 
   ====================== */
.goal-card {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease;
}

.goal-card:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.manager-progress-goal-name {
    font-size: 20px;
    color: #504a5a;
    margin-bottom: 1rem;
}

.goal-details {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.manager-progress-goal-text {
    color: #504a5a;
    line-height: 1.5;
    margin: 1rem 0;
    padding: 1rem;
    background: #faf7fd;
    border-radius: 4px;
}

/* ======================
   Progress Bar Styles 
   ====================== */
.progress-bar-container {
    margin: 1.5rem 0;
}

.progress-label {
    color: #504a5a;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.progress-bar {
    width: 100%;
    height: 8px;
    background: #eddef8;
    border-radius: 4px;
    overflow: hidden;
}

.progress-fill {
    height: 100%;
    background: #6908C9;
    transition: width 0.3s ease;
}

/* ======================
   Comments Section Styles 
   ====================== */
.comments-section {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #dcdcdc;
    width: 100%;
}

.manager-progress-goal-comment {
    width: 100%;
    min-height: 100px;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    margin: 1rem 0;
    transition: border-color 0.2s ease;
    box-sizing: border-box;
}

.manager-progress-goal-comment:focus {
    border-color: #6908C9;
    outline: none;
    box-shadow: 0 0 0 2px rgba(105, 8, 201, 0.1);
}

.comment-actions {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

/* Improved Comment History (chat-like interface) */
.comment-history {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 12px;
    max-height: 350px;
    overflow-y: auto;
}

.comment-item {
    padding: 1rem;
    border-radius: 12px;
    position: relative;
    max-width: 85%;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.comment-item.manager {
    align-self: flex-end;
    background-color: #6908C9;
    color: white;
    border-bottom-right-radius: 4px;
}

.comment-item.employee {
    align-self: flex-start;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
}

.comment-metadata {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-bottom: 5px;
}

.comment-author {
    font-weight: 600;
}

.comment-time {
    color: rgba(255,255,255,0.8);
}

.comment-item.employee .comment-time {
    color: #888;
}

.comment-content {
    line-height: 1.4;
}

.pending-comment {
    margin-top: 1rem;
    padding: 1rem;
    background: #eddef8;
    border-radius: 12px;
    font-style: italic;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    border-left: 4px solid #6908C9;
}

.pending-comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.pending-comment-header p {
    margin: 0;
    font-weight: 500;
    color: #6908C9;
}

.clear-feedback-button {
    background: none;
    border: none;
    color: #6908C9;
    font-size: 20px;
    cursor: pointer;
    padding: 0 5px;
    line-height: 1;
    transition: color 0.2s ease;
}

.clear-feedback-button:hover {
    color: #5607a3;
}

/* Feedback composer */
.feedback-composer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
    border: 1px solid #e0e0e0;
}

.feedback-composer-header {
    font-weight: 600;
    color: #504a5a;
    display: flex;
    align-items: center;
    gap: 8px;
}

.feedback-composer-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

/* ======================
   Button Styles 
   ====================== */
.back-link {
    background: none;
    color: #6908C9;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 0;
    margin-bottom: 2rem;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
}

.back-link:hover {
    color: #d1c4e9;
}

.action-button {
    background: none;
    border: 2px solid #6908C9;
    color: #6908C9;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.action-button:hover {
    background: #eddef8;
}

.action-button.primary {
    background: #6908C9;
    color: white;
}

.action-button.primary:hover {
    background: #5607a3;
}

/* Enhanced Submit Button */
.manager-progress-submit-container {
    display: flex;
    justify-content: center;
    margin: 50px 0;
}

.manager-progress-submit-link {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px 25px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(105, 8, 201, 0.3);
}

.manager-progress-submit-link:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(105, 8, 201, 0.4);
    background-color: #5a07aa;
}

.manager-progress-submit-link:disabled {
    background-color: #a881d6;
    cursor: not-allowed;
    box-shadow: none;
}

.submit-spinner {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: submit-spin 1s linear infinite;
}

@keyframes submit-spin {
    to { transform: rotate(360deg); }
}

/* ======================
   Message Styles 
   ====================== */
.no-goals-message {
    text-align: center;
    padding: 2rem;
    background: #faf7fd;
    border-radius: 8px;
    color: #504a5a;
}

/* ======================
   Media Queries 
   ====================== */
@media screen and (max-width: 768px) {
    .goal-card {
        padding: 1rem;
    }
    
    .comment-actions {
        flex-direction: column;
    }
    
    .action-button {
        width: 100%;
    }

    .goal-details {
        margin-top: 0.5rem;
        gap: 0.5rem;
    }
}

/* Verification section styles */
.manager-progress-verification-actions {
    margin-top: 15px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border-left: 4px solid #f39c12;
}

.manager-progress-verification-actions h4 {
    margin-top: 0;
    color: #333;
    font-size: 1.1rem;
}

.manager-progress-verification-actions p {
    margin-bottom: 15px;
    color: #555;
}

.manager-progress-verification-notes {
    background-color: #fff;
    border-radius: 6px;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #eee;
}

.manager-progress-verification-notes h5 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 0.95rem;
    color: #333;
}

.manager-progress-verification-notes p {
    margin: 0;
    font-style: italic;
    color: #555;
    line-height: 1.4;
}

.manager-progress-verification-buttons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

.manager-progress-verify-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: opacity 0.2s;
}

.manager-progress-verify-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.manager-progress-verify-button.manager-progress-approve {
    background-color: #27ae60;
    color: white;
}

.manager-progress-verify-button.manager-progress-approve:hover:not(:disabled) {
    background-color: #219653;
}

.manager-progress-verify-button.manager-progress-reject {
    background-color: #e74c3c;
    color: white;
}

.manager-progress-verify-button.manager-progress-reject:hover:not(:disabled) {
    background-color: #c0392b;
}

/* Spinner animation */
.manager-progress-fa-spin {
    animation: manager-progress-fa-spin 2s infinite linear;
}

@keyframes manager-progress-fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

/* Add these styles for the check-in feature */

/* Check-in buttons */
.manager-progress-actions {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    justify-content: center;
}

.manager-progress-checkin-button,
.manager-progress-history-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 18px;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.manager-progress-checkin-button {
    background-color: #6908C9;
    color: white;
}

.manager-progress-history-button {
    background-color: #f0f2f5;
    color: #504a5a;
}

.manager-progress-checkin-button:hover,
.manager-progress-history-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Check-in history section */
.manager-progress-checkin-history {
    margin: 25px 0;
    padding: 25px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    border: 1px solid #eaeaea;
}

.manager-progress-checkin-history h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.25rem;
    color: #333;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
}

/* Check-in list */
.checkin-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkin-item {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
    border-left: 4px solid transparent;
    position: relative;
    overflow: hidden;
}

.checkin-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.checkin-item.checkin-scheduled {
    border-left-color: #4a6cf7;
    background-color: #f5f8ff;
}

.checkin-item.checkin-completed {
    border-left-color: #4caf50;
    background-color: #f5fff7;
}

.checkin-item.checkin-cancelled {
    border-left-color: #f44336;
    background-color: #fff5f5;
    opacity: 0.8;
}

.checkin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.checkin-date {
    font-weight: 600;
    font-size: 1rem;
    color: #333;
}

.checkin-status {
    display: flex;
    align-items: center;
}

.status-badge {
    padding: 6px 12px;
    border-radius: 30px;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.checkin-item.checkin-scheduled .status-badge {
    background-color: #e3f2fd;
    color: #1976d2;
}

.checkin-item.checkin-completed .status-badge {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.checkin-item.checkin-cancelled .status-badge {
    background-color: #ffebee;
    color: #c62828;
}

.checkin-completed-date,
.checkin-notes {
    margin: 12px 0;
    font-size: 0.95rem;
    line-height: 1.5;
    color: #555;
}

.checkin-notes strong {
    color: #333;
}

.checkin-actions {
    display: flex;
    gap: 12px;
    margin-top: 20px;
}

.checkin-complete-button,
.checkin-cancel-button {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 6px;
}

.checkin-complete-button {
    background-color: #4caf50;
    color: white;
}

.checkin-cancel-button {
    background-color: #f44336;
    color: white;
}

.checkin-complete-button:hover,
.checkin-cancel-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.15);
}

/* Check-in modal */
.manager-progress-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    padding: 20px 0;
    backdrop-filter: blur(3px);
}

.manager-progress-modal-content {
    width: 90%;
    max-width: 550px;
    background-color: white;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    margin: auto;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.manager-progress-modal-content h3 {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 1.4rem;
    color: #333;
    text-align: center;
    font-weight: 600;
}

.manager-progress-form-group {
    margin-bottom: 20px;
    width: 100%;
}

.manager-progress-form-group label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: 500;
    font-size: 0.95rem;
}

.manager-progress-form-group input,
.manager-progress-form-group textarea {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.2s ease;
    background-color: #f9f9f9;
    color: #333;
    box-sizing: border-box;
}

.manager-progress-form-group input:focus,
.manager-progress-form-group textarea:focus {
    border-color: #6908C9;
    outline: none;
    box-shadow: 0 0 0 3px rgba(105, 8, 201, 0.1);
    background-color: #fff;
}

.manager-progress-goal-selection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 200px;
    overflow-y: auto;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    margin-top: 5px;
}

.manager-progress-goal-checkbox {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid #e0e0e0;
}

.manager-progress-goal-checkbox:hover {
    background-color: #f0f0f0;
}

.manager-progress-goal-checkbox input[type="checkbox"] {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    position: relative;
    cursor: pointer;
    flex: 0 0 20px;
}

.manager-progress-goal-checkbox label {
    margin-bottom: 0;
    cursor: pointer;
    flex: 1;
    font-weight: normal;
    color: #333;
}

.manager-progress-goal-checkbox input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manager-progress-goal-checkbox input[type="checkbox"]:checked {
    background-color: #6908C9;
    border-color: #6908C9;
}

.manager-progress-goal-checkbox input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.manager-progress-goal-checkbox input[type="checkbox"]:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(105, 8, 201, 0.2);
}

.manager-progress-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 30px;
}

.manager-progress-modal-cancel,
.manager-progress-modal-submit {
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 120px;
}

.manager-progress-modal-cancel {
    background-color: #f0f2f5;
    color: #504a5a;
}

.manager-progress-modal-submit {
    background-color: #6908C9;
    color: white;
}

.manager-progress-modal-cancel:hover,
.manager-progress-modal-submit:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Responsive styles */
@media (max-width: 768px) {
    .manager-progress-actions {
        flex-direction: column;
    }
    
    .checkin-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .checkin-status {
        margin-top: 5px;
    }
    
    .checkin-actions {
        flex-direction: column;
    }
}

/* Check-in empty state */
.no-checkins-message {
    text-align: center;
    padding: 30px 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.no-checkins-icon {
    font-size: 3rem;
    color: #ccc;
    margin-bottom: 10px;
}

.no-checkins-message p {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
}

/* Additional check-in styles */
.checkin-icon {
    margin-right: 8px;
}

.checkin-employee-notes {
    margin: 12px 0;
    padding: 12px 15px;
    background-color: #f0f9ff;
    border-radius: 8px;
    font-size: 0.95rem;
    line-height: 1.5;
    border-left: 3px solid #4a6cf7;
}

.checkin-employee-notes strong {
    color: #333;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 6px;
}

/* Enhanced goal checkbox styling */
.goal-checkbox-title {
    font-weight: 500;
    margin-bottom: 6px;
    color: #333;
}

.goal-checkbox-progress {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.85rem;
    color: #666;
}

.goal-checkbox-progress-bar {
    flex: 1;
    height: 6px;
    background-color: #eee;
    border-radius: 3px;
    overflow: hidden;
}

.goal-checkbox-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 3px;
}

/* No goals message in check-in modal */
.no-goals-for-checkin {
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    color: #666;
    font-style: italic;
    text-align: center;
}

/* Form field helper text */
.manager-progress-form-group small {
    display: block;
    margin-top: 6px;
    color: #666;
    font-size: 0.85rem;
    font-style: italic;
}

/* Loading spinner for modal button */
.modal-spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255,255,255,0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    margin-right: 8px;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* Time select styles */
.time-select-container {
    position: relative;
}

.time-select {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.2s ease;
    background-color: #f9f9f9;
    color: #333;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    background-size: 12px auto;
    padding-right: 35px;
}

.time-select:focus {
    border-color: #6908C9;
    outline: none;
    box-shadow: 0 0 0 3px rgba(105, 8, 201, 0.1);
    background-color: #fff;
}

/* Add styles for the delete button */
.checkin-delete-button {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: #6c757d;
    color: white;
}

.checkin-delete-button:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.15);
}

/* Custom Notes Modal Styling */
.custom-notes-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    backdrop-filter: blur(3px);
}

.custom-notes-modal-content {
    width: 90%;
    max-width: 450px;
    background-color: white;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

.custom-notes-modal-header {
    margin-bottom: 15px;
    text-align: center;
}

.custom-notes-modal-header h3 {
    font-size: 1.2rem;
    color: #333;
    margin: 0 0 5px 0;
}

.custom-notes-modal-header p {
    font-size: 0.9rem;
    color: #666;
    margin: 0;
}

.custom-notes-modal textarea {
    width: 100%;
    min-height: 120px;
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    resize: vertical;
    transition: all 0.2s ease;
    background-color: #f9f9f9;
    color: #333;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.custom-notes-modal textarea:focus {
    border-color: #6908C9;
    outline: none;
    box-shadow: 0 0 0 3px rgba(105, 8, 201, 0.1);
    background-color: #fff;
}

.custom-notes-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.custom-notes-modal-cancel,
.custom-notes-modal-submit {
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.custom-notes-modal-cancel {
    background-color: #f0f2f5;
    color: #504a5a;
}

.custom-notes-modal-submit {
    background-color: #6908C9;
    color: white;
    display: flex;
    align-items: center;
    gap: 6px;
}

.custom-notes-modal-cancel:hover,
.custom-notes-modal-submit:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
