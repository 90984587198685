.achievements-page-container {
    font-family: 'Literata', serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.back-button-container {
    margin: 1rem 0;
    position: relative;
    width: 100%;
}

.achievements-page-header {
    color: #6908C9;
    font-size: 2rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #eddef8;
    padding-bottom: 1rem;
    text-align: center;
}

.achievements-stats-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 2.5rem;
}

.achievement-stat-card {
    background-color: #f9f6ff;
    border-radius: 10px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.achievement-stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.2);
}

.achievement-stat-value {
    font-size: 2.5rem;
    font-weight: bold;
    color: #6908C9;
    display: block;
    margin-bottom: 0.5rem;
}

.achievement-stat-label {
    font-size: 1rem;
    color: #504a5a;
}

.achievements-loading {
    text-align: center;
    padding: 3rem;
    color: #666;
    font-style: italic;
}

.achievements-content {
    margin-top: 2rem;
}

.achievement-category {
    margin-bottom: 3rem;
}

.achievement-category-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #6908C9;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eddef8;
}

.achievement-category-title svg {
    color: #6908C9;
}

.achievements-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.achievement-card {
    background-color: white;
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    min-height: 160px;
}

.achievement-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.1);
}

.achievement-icon-container {
    background-color: #f0e6fa;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.achievement-icon-container svg {
    font-size: 24px;
    color: #6908C9;
}

.achievement-card-content {
    flex-grow: 1;
}

.achievement-title {
    font-size: 1.2rem;
    color: #333;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.achievement-description {
    font-size: 0.95rem;
    color: #666;
    margin-bottom: 0.75rem;
}

.achievement-date {
    font-size: 0.8rem;
    color: #6908C9;
    font-style: italic;
}

.achievement-requirements {
    font-size: 0.85rem;
    color: #777;
    font-style: italic;
    border-top: 1px dashed #e0e0e0;
    padding-top: 0.75rem;
    margin-top: 0.75rem;
}

/* Styles for locked achievements */
.locked-achievement {
    opacity: 0.7;
    background-color: #f5f5f5;
    position: relative;
}

.locked-achievement:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    z-index: 1;
}

.locked-achievement:after {
    content: '🔒';
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    z-index: 2;
}

.locked-achievement .achievement-icon-container {
    background-color: #e9e9e9;
}

.locked-achievement .achievement-icon-container svg {
    color: #999;
}

/* Icon Colors */
.achievement-icon-complete {
    color: #6908C9 !important;
}

.achievement-icon-streak {
    color: #6908C9 !important;
}

.achievement-icon-progress {
    color: #6908C9 !important;
}

.achievement-icon-team {
    color: #6908C9 !important;
}

.achievement-icon-company {
    color: #6908C9 !important;
}

.achievement-icon-default {
    color: #6908C9 !important;
}

/* Media queries for responsive layout */
@media screen and (max-width: 1024px) {
    .achievements-stats-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .achievements-page-container {
        padding: 0 1rem;
    }
    
    .achievement-category-title {
        font-size: 1.3rem;
    }
    
    .achievements-grid {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 480px) {
    .achievements-stats-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
    
    .achievement-stat-value {
        font-size: 1.8rem;
    }
    
    .achievement-stat-label {
        font-size: 0.85rem;
    }
    
    .achievement-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1.25rem;
    }
    
    .achievement-icon-container {
        margin-bottom: 1rem;
    }
} 