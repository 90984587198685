.contact-body {
    background-color: white;
    min-height: 100vh;
    margin: 0;
    padding: 2rem;
}

.contact-body h1 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 2rem;
}

.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem;
}

.contact-box {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 2rem;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
}

.contact-box:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
    transform: translateY(-2px);
}

.contact-box h2 {
    font-size: 24px;
    color: #333;
    margin: 0;
}

.contact-box p {
    font-size: 16px;
    color: #504a5a;
    margin: 0;
    flex-grow: 1;
}

.contact-button {
    background: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    padding: 0.8rem 1.5rem;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
    display: inline-block;
    min-width: 150px;
}

.contact-button:hover {
    background: #6908C9;
    color: white;
    transform: translateY(-1px);
}

.contact-h3-container {
    max-width: 800px;
    margin: 3rem auto 0;
    text-align: center;
    padding: 0 1rem;
}

.contact-h3 {
    font-size: 18px;
    color: #504a5a;
    font-weight: normal;
    line-height: 1.5;
}

.contact-h3 a {
    color: #6908C9;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
}

.contact-h3 a:hover {
    color: #d1c4e9;
}

@media (max-width: 768px) {
    .contact-body {
        padding: 1rem;
    }

    .contact-body h1 {
        font-size: 24px;
        margin-bottom: 1.5rem;
    }

    .contact-container {
        gap: 1rem;
    }

    .contact-box {
        padding: 1.5rem;
    }

    .contact-h3 {
        font-size: 16px;
    }
}
