/* ========================================
   AdminMaster.js Styles
   ======================================== */
.admin-goal-set-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Loading States */
.loading-overlay {
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.loading-text {
    margin-top: 20px;
    color: black;
    font-size: 1.2rem;
    font-weight: 500;
}

/* Common Styles */
.admin-goal-set-title {
    color: black;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.admin-goal-set-description {
    color: #504a5a;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2rem;
}

.admin-goal-set-button-container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    gap: 40px;
}

.admin-goal-set-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.admin-goal-set-link:hover {
    color: #d1c4e9;
}

.admin-goal-set-submit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.admin-goal-set-submit-link:hover {
    color: #d1c4e9;
}

/* ========================================
   Common Container Styles
   ======================================== */
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: white;
}

.page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.admin-goal-set-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* ========================================
   AdminStep1.js Styles
   ======================================== */
.admin-goal-set-back-wrapper {
    align-self: flex-start;
}

.admin-goal-set-flex-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 800px;
    margin: 1rem 0;
}

.admin-goal-set-input {
    width: 100%;
    min-height: 200px;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
}

.admin-goal-set-input:read-only {
    background-color: #f0f0f0;
    border-color: #dcdcdc;
    cursor: default;
}

.admin-goal-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.action-button.confirm {
    background: #6908C9;
    color: white;
    border: none;
}

.action-button.edit {
    background: white;
    color: #6908C9;
    border: 1px solid #6908C9;
}

.action-button.delete {
    background: white;
    color: #dc3545;
    border: 1px solid #dc3545;
}

.action-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

/* ========================================
   AdminStep2.js Styles
   ======================================== */
.admin-goal-item {
    width: 100%;
    display: flex;
    gap: 1rem;
    position: relative;
}

.admin-goal-content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.admin-goal-actions {
    width: 100%;
    max-width: 800px;
    display: flex;
    gap: 1rem;
    margin: 0 auto 2rem;
    align-items: center;
    justify-content: center;
}

.admin-goal-set-add-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    background: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 150px;
}

.admin-goal-set-add-button:hover {
    background: #eddef8;
    transform: translateY(-1px);
}

.admin-goals-container {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
}

/* ========================================
   AdminStep3.js Styles
   ======================================== */
.admin-goal-set-review-container {
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-goal-set-review-container h3.admin-goal-set-title {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 20px;
}

.admin-goals-review-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.admin-goal-review-item {
    width: 100%;
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;
    transition: all 0.2s ease;
}

.admin-goal-review-item:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.admin-goal-review-content {
    font-size: 16px;
    line-height: 1.5;
    color: #504a5a;
    width: 100%;
}

.admin-goal-review-input {
    width: 100%;
    min-height: 100px;
    padding: 1rem;
    border: 1px solid #6908C9;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    background-color: white;
}

/* ========================================
   Media Queries
   ======================================== */
@media screen and (max-width: 768px) {
    .admin-goal-set-container {
        padding: 1rem;
    }

    .page-content {
        padding: 1rem;
    }

    .admin-goal-set-flex-container,
    .admin-goals-container,
    .admin-goal-set-review-container {
        width: calc(100% - 30px);
        margin: 1rem 15px;
    }

    .admin-goal-set-button-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }
}

@media screen and (max-width: 480px) {
    .admin-goal-set-title {
        font-size: 24px;
    }

    .admin-goal-set-link,
    .admin-goal-set-submit-link {
        font-size: 16px;
    }

    .loading-text {
        font-size: 1rem;
        padding: 0 15px;
    }
}


