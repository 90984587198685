.employee-profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    min-height: 40vh;
    align-items: stretch;
}

.back-button-container {
    margin-bottom: 20px;
    align-self: flex-start;
    position: relative;
    z-index: 2;
}

.page-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}

.employee-profile-card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    width: 35%;
}

.employee-profile-card-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
    gap: 40px;
}

.employee-profile-card-edit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 0;
}

.employee-profile-card-edit-link:hover {
    color: #d1c4e9;
}

.employee-profile-info {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 65%;
    margin-right: 20px;
}

.employee-profile-goals-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
    align-items: stretch;
}

.employee-profile-goals {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 20px;
    height: 100%;
}

/* ======================
   Layout & Container Styles 
   ====================== */
.employee-profile-body {
    background-color: white;
    min-height: 100vh;
    padding: 2rem;
}

.employee-profile-page-container {
    display: flex;
    flex-direction: column;
}

.employee-profile-back-button-container {
    align-self: flex-start;
}

.employee-profile-page-content {
    max-width: 1200px;
    margin: 0 auto;
}

/* ======================
   Header Styles 
   ====================== */
.employee-profile-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #eddef8;
    width: 100%;
    margin-top: 15px;
}

.employee-profile-main-info {
    flex: 1;
    padding-right: 20px;
}

.employee-profile-main-info h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.employee-profile-job-title {
    font-size: 1.25rem;
    color: #6908C9;
    margin-bottom: 0.5rem;
}

.employee-profile-email {
    color: #504a5a;
}

.employee-profile-edit-button {
    background: none;
    border: 2px solid #6908C9;
    color: #6908C9;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto;
    white-space: nowrap;
}

.employee-profile-edit-button:hover {
    background: #eddef8;
}

/* ======================
   Section Styles 
   ====================== */
.employee-profile-sections {
    display: grid;
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
}

.employee-profile-section {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    padding: 2rem;
    transition: all 0.2s ease;
    width: 100%;
    box-sizing: border-box;
}

.employee-profile-section:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.employee-profile-section:last-child {
    margin-bottom: 2rem;
}

.employee-profile-section-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.employee-profile-section-icon {
    color: #6908C9;
    font-size: 1.5rem;
}

.employee-profile-section-header h2 {
    color: #333;
    font-size: 1.5rem;
    margin: 0;
}

.employee-profile-section-content {
    color: #504a5a;
    line-height: 1.6;
}

/* ======================
   Reports Grid Styles 
   ====================== */
.employee-profile-reports-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
    width: 100%;
}

.employee-profile-report-card {
    background: #faf7fd;
    padding: 1.5rem;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.employee-profile-report-card:hover {
    background: #eddef8;
}

.employee-profile-report-card h3 {
    color: #333;
    margin: 0 0 0.5rem 0;
}

.employee-profile-report-card p {
    color: #6908C9;
    margin: 0;
}

/* ======================
   Goals Grid Styles - Refined
   ====================== */
.employee-profile-goals-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    width: 100%;
}

.employee-profile-goal-card {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 1.5rem;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    height: auto;
}

.employee-profile-goal-card:hover {
    border-color: #6908C9;
    box-shadow: 0 3px 10px rgba(105, 8, 201, 0.1);
}

.employee-profile-goal-title {
    color: #333;
    margin: 0 0 1rem 0;
    font-size: 1.1rem;
    line-height: 1.4;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 2.8rem;
}

.employee-profile-progress-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 10px;
}

.employee-profile-progress-indicator {
    background: #f0f0f0;
    height: 8px;
    border-radius: 4px;
    flex-grow: 1;
    overflow: hidden;
}

.employee-profile-progress-bar {
    background: linear-gradient(90deg, #6908C9, #9c42f5);
    height: 100%;
    transition: width 0.5s ease;
}

.employee-profile-progress-label {
    color: #6908C9;
    font-weight: 600;
    font-size: 0.9rem;
    min-width: 40px;
    text-align: right;
}

.employee-profile-goal-details {
    transition: all 0.3s ease;
    max-height: 0;
    overflow: hidden;
    width: 100%;
}

.employee-profile-goal-details.expanded {
    max-height: 300px;
    overflow-y: auto;
    margin: 1rem 0;
    padding-right: 5px;
}

.employee-profile-goal-text {
    color: #504a5a;
    margin: 0;
    line-height: 1.6;
    font-size: 0.9rem;
    white-space: pre-line;
    overflow-wrap: break-word;
    word-break: break-word;
}

.employee-profile-toggle-button {
    background: none;
    border: none;
    color: #6908C9;
    cursor: pointer;
    padding: 0;
    font-size: 0.85rem;
    margin-top: 1rem;
    align-self: flex-start;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: all 0.2s ease;
}

.employee-profile-toggle-button:hover {
    color: #5607a3;
    text-decoration: underline;
}

.employee-profile-no-goals {
    grid-column: 1 / -1;
    text-align: center;
    color: #757575;
    font-style: italic;
    padding: 2rem;
}

/* ======================
   Media Queries 
   ====================== */
@media screen and (max-width: 768px) {
    .page-container {
        padding: 0 1rem;
    }

    .employee-profile-header {
        flex-direction: column;
        gap: 1rem;
    }

    .employee-profile-main-info h1 {
        font-size: 2rem;
    }

    .employee-profile-reports-grid,
    .employee-profile-goals-grid {
        grid-template-columns: 1fr;
    }

    .employee-profile-section {
        padding: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .employee-profile-main-info h1 {
        font-size: 1.75rem;
    }

    .employee-profile-section-header h2 {
        font-size: 1.25rem;
    }
}
