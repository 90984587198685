html {
  margin: 0;
  padding: 0;
  height: 100%;
}


.content {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
}

body{
  font-family: "Literata", "PT Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
}

p {
  font-family: "Literata", sans-serif;
  font-weight: 400;
  font-style: normal; 
}

button {
  font-family: "Literata", sans-serif;
}

textarea {
  border-radius: 5px;
  font-size: 16px;
  font-family: "Literata", sans-serif;
}

/* Add these new base container styles */
.page-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.page-content {
  flex: 1;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 0;
}

.back-button-container {
  width: fit-content;
  margin-bottom: 25px;
  position: absolute;
  left: 20px;
  top: 40px;
}

/* Add a new class for page headers to ensure consistent spacing */
.page-header {
  margin-top: 15px;
  margin-bottom: 30px;
  text-align: center;
}

/* Add consistent spacing for form containers */
.form-container {
  background-color: white;
  padding: 2rem;
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .page-container {
    padding: 30px 15px;
  }
  
  .back-button-container {
    top: 30px;
  }
}