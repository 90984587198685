.admin-portal {
    display: flex;
    font-family: 'Literata', serif;
    background-color: white;
    min-height: 100vh;
}

.admin-portal-sidebar {
    width: 250px;
    background-color: #f8f8f8;
    padding: 2rem 1rem;
    height: 100vh;
    border-right: 1px solid #dcdcdc;
    transition: all 0.3s ease;
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
}

.admin-portal-sidebar-button {
    font-family: 'Literata', serif;
    font-size: 16px;
    border: none;
    background-color: transparent;
    display: flex;
    padding: 1rem 1.2rem;
    text-decoration: none;
    color: #504a5a;
    margin-bottom: 0.8rem;
    width: 100%;
    border-radius: 8px;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    justify-content: flex-start;
    text-align: left;
}

.admin-portal-sidebar-button:hover {
    background-color: #eddef8;
    color: #6908C9;
    text-decoration: none;
    transform: translateX(3px);
}

.admin-portal-sidebar-button:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: #6908C9;
    transform: scaleY(0);
    transition: transform 0.2s ease;
}

.admin-portal-sidebar-button:hover:before {
    transform: scaleY(1);
}

.admin-portal-content {
    flex-grow: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.admin-portal-menu-button {
    display: none;
    font-size: 18px;
    font-family: 'Literata', serif;
    cursor: pointer;
    margin: 10px;
    background: none;
    border: none;
    color: #6908C9;
    transition: color 0.3s;
}

.admin-portal-menu-button:hover {
    color: #d1c4e9;
}

.admin-portal-sidebar.mobile {
    display: none;
    position: fixed;
    top: 0;
    left: -250px;
    width: 200px;
    height: 100%;
    background-color: #f4f4f4;
    transition: left 0.3s ease;
    z-index: 1000;
}

.admin-portal-sidebar.mobile.open {
    left: 0;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .admin-portal-menu-button {
        display: flex;
        margin-top: 20px;
    }

    .admin-portal-sidebar {
        display: none;
    }

    .admin-portal-sidebar.mobile {
        display: block;
    }

    .admin-portal-sidebar-button, 
    .admin-portal-sidebar.mobile .admin-portal-sidebar-button {
        justify-content: flex-start;
        text-align: left;
    }
}

/* Primary purple buttons for portal actions */
.admin-goal-setting-button {
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.admin-goal-setting-button:hover {
    background-color: #5607a3;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.1);
}

/* White buttons specifically for dashboard cards */
.admin-dashboard-button {
    background-color: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    padding: 10px 18px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.admin-dashboard-button:hover {
    background-color: #eddef8;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(105, 8, 201, 0.1);
}

.admin-goal-setting-button.primary {
    background-color: #6908C9;
    color: white;
}

.admin-goal-setting-button.primary:hover {
    background-color: #5607a3;
}

.admin-goal-setting-button-container {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

/* Content Section Styles */
.portal-section {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
}

.portal-section:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.08);
    transform: translateY(-2px);
}

.portal-section h2 {
    color: #6908C9;
    font-size: 24px;
    margin-bottom: 1rem;
    font-weight: 600;
}

.portal-section p {
    color: #504a5a;
    font-size: 16px;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

/* Goal Setting Period Button Styles */
.admin-goal-setting-period-button {
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
}

.admin-goal-setting-period-button.active {
    background-color: #e74c3c;  /* Red when open */
    color: white;
}

.admin-goal-setting-period-button:not(.active) {
    background-color: #27ae60;  /* Green when closed */
    color: white;
}

.admin-goal-setting-period-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    opacity: 0.95;
}

.goal-setting-inactive-message {
    text-align: center;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
}

/* Status section styling */
.goal-setting-status {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border-left: 4px solid #6908C9;
}

.goal-setting-status h3 {
    color: #333;
    margin-bottom: 0.5rem;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.goal-setting-status h3:before {
    content: '•';
    color: #6908C9;
    font-size: 24px;
}

/* Add this to handle link styling */
a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

/* Fix for active sidebar buttons */
.admin-portal-sidebar-button.active {
    background-color: #eddef8;
    color: #6908C9;
    font-weight: 500;
}

.admin-portal-sidebar-button.active:before {
    transform: scaleY(1);
}

/* This ensures both hover and active states work correctly */
.admin-portal-sidebar-button:hover:not(.active) {
    background-color: #eddef8;
    color: #6908C9;
    text-decoration: none;
    transform: translateX(3px);
}

.admin-portal-sidebar-button:hover:not(.active):before {
    transform: scaleY(1);
}

/* Admin Dashboard Preview Styles */
.admin-dashboard-preview {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1.5rem 0;
}

.admin-dashboard-company-stats {
    background: #f8f5fd;
    border-radius: 8px;
    padding: 1.5rem;
    border: 1px solid #e0d5f0;
}

.admin-dashboard-company-stats h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #504a5a;
    font-size: 18px;
    border-bottom: 1px solid #e0d5f0;
    padding-bottom: 0.5rem;
}

.admin-stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
}

.admin-stat-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.admin-stat-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #6908C9;
}

.admin-stat-label {
    font-size: 14px;
    color: #504a5a;
    margin-top: 0.5rem;
    text-align: center;
}

.admin-dashboard-personal {
    background: #f8f5fd;
    border-radius: 8px;
    padding: 1.5rem;
    border: 1px solid #e0d5f0;
    display: flex;
    flex-direction: column;
}

.admin-dashboard-personal h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #504a5a;
    font-size: 18px;
    border-bottom: 1px solid #e0d5f0;
    padding-bottom: 0.5rem;
}

.admin-personal-stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.admin-dashboard-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: auto;
}

@media screen and (min-width: 768px) {
    .admin-dashboard-preview {
        flex-direction: row;
    }
    
    .admin-dashboard-company-stats,
    .admin-dashboard-personal {
        flex: 1;
        min-height: 280px;
        display: flex;
        flex-direction: column;
    }
    
    .admin-dashboard-buttons {
        margin-top: auto;
    }
}

.admin-team-dashboard-container {
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid #e0e0e0;
}

.admin-team-dashboard-container h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.3rem;
}

.view-dashboard-button {
    background-color: white;
    color: #6908C9;
    border: 2px solid #6908C9;
    border-radius: 8px;
    padding: 0.6rem 1.2rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 150px;
    white-space: nowrap;
    text-decoration: none;
}

.view-dashboard-button:hover {
    background-color: #6908C9;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(105, 8, 201, 0.15);
}

/* Ensure TeamDashboard component buttons match branding */
.team-dashboard-container .post-button,
.team-dashboard-container .save-progress-button {
    background-color: #6908C9;
    color: white;
    transition: all 0.2s ease;
}

.team-dashboard-container .post-button:hover,
.team-dashboard-container .save-progress-button:hover {
    background-color: #5607a3;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(105, 8, 201, 0.15);
}

.team-dashboard-container .edit-progress-button:hover {
    background-color: #eddef8;
    color: #6908C9;
}

/* Ensure progress bars match branding */
.team-dashboard-container .team-goal-progress-fill {
    background-color: #6908C9;
}

.admin-dashboard-centered-button {
    display: flex;
    justify-content: center;
    margin-top: auto;
}

/* Fix for Team Feed textarea width */
.team-dashboard-container textarea {
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
    min-height: 80px;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-family: 'Literata', serif;
    margin-bottom: 10px;
}

/* Admin Dashboard Grid Layout */
.admin-dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin: 1.5rem 0;
}

/* For mobile screens */
@media screen and (max-width: 768px) {
    .admin-dashboard-grid {
        grid-template-columns: 1fr;
    }
}

/* Dashboard Card Styles */
.admin-dashboard-card {
    background: #f8f5fd;
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid #e0d5f0;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
}

.admin-dashboard-card:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.1);
    transform: translateY(-3px);
}

.admin-dashboard-card-header {
    margin-bottom: 1.2rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #e0d5f0;
}

.admin-dashboard-card-header h3 {
    color: #504a5a;
    font-size: 18px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
}

.admin-dashboard-card .card-icon {
    color: #6908C9;
}

.admin-dashboard-buttons {
    margin-top: auto;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}

.admin-dashboard-loading {
    text-align: center;
    padding: 2rem 0;
    color: #888;
    font-style: italic;
}

.admin-no-data-message {
    text-align: center;
    padding: 2rem 0;
    color: #888;
    font-style: italic;
}

/* Check-ins Styles */
.admin-checkins-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.admin-checkin-item {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.admin-checkin-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-checkin-date {
    font-weight: 500;
    margin-bottom: 0.3rem;
    color: #6908C9;
}

.admin-checkin-employee {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

.admin-checkin-notes {
    padding-top: 0.5rem;
    border-top: 1px dashed #e0e0e0;
    font-size: 0.9rem;
    font-style: italic;
    color: #666;
}

/* Goals Styles */
.admin-goals-summary {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.admin-goals-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.admin-goals-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.admin-goal-item {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

.admin-goal-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-goal-summary {
    margin-bottom: 0.8rem;
    font-weight: 500;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
}

.admin-goal-progress-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.admin-goal-progress-bar {
    flex: 1;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
}

.admin-goal-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 4px;
    transition: width 0.3s ease;
}

.admin-goal-progress-text {
    font-size: 0.9rem;
    font-weight: 600;
    color: #6908C9;
    min-width: 40px;
    text-align: right;
}

/* Archive section styles */
.admin-archive-section {
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid #e0e0e0;
}

.admin-archive-button {
    background-color: #ff6b6b;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    margin-top: 1rem;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;
}

.admin-archive-button:hover {
    background-color: #ff5252;
    transform: translateY(-2px);
}

/* Confirmation modal */
.admin-confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.admin-confirmation-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.admin-confirmation-content h3 {
    margin-top: 0;
    color: #333;
}

.admin-warning {
    color: #ff5252;
    font-weight: bold;
    margin: 1rem 0;
}

.admin-confirmation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.admin-confirm-button {
    background-color: #ff5252;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.admin-confirm-button:hover {
    background-color: #ff3b3b;
}

.admin-cancel-button {
    background-color: #e0e0e0;
    color: #333;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.admin-cancel-button:hover {
    background-color: #d1d1d1;
}

/* Archived goals display */
.admin-archived-goals-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.admin-archived-section {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1.5rem;
}

.admin-archived-section h3 {
    color: #6908C9;
    margin-bottom: 1rem;
}

.admin-archived-goals-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

.admin-archived-goal-card {
    background-color: #f9f6ff;
    border: 1px solid #e0d5f0;
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.2s ease;
}

.admin-archived-goal-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
}

.admin-archived-goal-content h4 {
    color: #504a5a;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.admin-archived-goal-progress {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.8rem 0;
}

.admin-archived-progress-bar {
    flex: 1;
    height: 8px;
    background-color: #eeeeee;
    border-radius: 4px;
    overflow: hidden;
}

.admin-archived-progress-fill {
    height: 100%;
    background-color: #6908C9;
    border-radius: 4px;
}

.admin-archived-progress-text {
    font-size: 0.85rem;
    font-weight: 600;
    color: #6908C9;
    min-width: 40px;
    text-align: right;
}

.admin-archived-date {
    font-size: 0.75rem;
    color: #888;
    margin-top: 0.5rem;
    font-style: italic;
}

.admin-no-archived {
    color: #888;
    font-style: italic;
    text-align: center;
    padding: 1rem;
}

.admin-loading {
    text-align: center;
    padding: 2rem;
    color: #888;
    font-style: italic;
}

/* Unarchive button styling */
.admin-archived-actions {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.admin-unarchive-button {
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.admin-unarchive-button:hover {
    background-color: #5607A3;
    transform: translateY(-2px);
}

/* Add rules for disabled buttons */
.admin-portal-sidebar-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #f0f0f0;
    color: #999;
}

.admin-portal-sidebar-button.disabled:hover {
    background-color: #f0f0f0;
    color: #999;
    transform: none;
    box-shadow: none;
}

.admin-portal-sidebar-button.disabled:before {
    display: none;
}
