.team-members-body {
    background-color: white;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

.page-content {
    max-width: 1200px;
    margin: 0 auto;
}

.page-header {
    text-align: left;
    margin-bottom: 30px;
    color: #333;
    font-size: 28px;
    font-weight: bold;
}

.employee-directory {
    padding: 20px;
    padding-top: 0;
    max-width: 800px;
    width: 100%;
}

.team-members-list {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    width: 100%;
}

.team-members-list li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 15px;
    font-size: 16px;
    text-indent: 0;
    display: flex;
    align-items: center;
}

.team-members-list li:before {
    content: "\2605";
    color: #6908C9;
    position: absolute;
    left: 0;
    top: 0;
}

.team-members-list li a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
}

.team-members-list li a:hover {
    color: #6908C9;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .page-content {
        padding: 1rem;
    }
}

.back-button-container {
    align-self: flex-start;
    margin-bottom: 20px;
}


