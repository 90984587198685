/* General styles */
.home-body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
    color: #fff;
    box-sizing: border-box;
}

/* Navigation bar styles */
.landingPageNavBar-container {
    position: fixed;
    width: 100%; /* Full width of the viewport */
    display: flex;
    justify-content: space-between; /* Space between left and right */
    align-items: center; /* Center items vertically */
    top: 0;
    z-index: 1000; /* Ensure it stays on top */
    left: 0; /* Align to the left edge */
    right: 0; /* Align to the right edge */
    height: 75px;
}

.home-navbar {
    height: 75px;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.landingPageNavBarLeft,
.landingPageNavBarRight {
    display: flex;
    align-items: center; /* Center items vertically */
}

.landingPageNavBarLeft {
    margin-left: 20px;
}

.landingPageNavBarRight {
    margin-left: auto;
    margin-right: 20px;
}

.landingPageNavBarHamburger {
    display: flex;
    align-items: center;
}

.home-navLink-logo {
    font-size: 24px;
    font-weight: bold;
    font-family: "Ubuntu", "PT Sans", sans-serif;
    cursor: pointer;
    transition: color 0.3s;
    margin-left: 20px;
    color: white;
    text-decoration: none;
}

.home-navLink-logo:hover {
    color: #d1c4e9;
}

.home-navLink {
    color: white; /* Link color */   
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
    font-size: 18px; /* Font size */
    font-family: "Literata", serif;
    cursor: pointer;
}

.home-navLink:hover {
    color: #d1c4e9; /* Change color on hover */
}

.home-navLink-button {
    color: white; /* Link color */
    font-size: 18px;   
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
    background: none;
    border: none;
    cursor: pointer;
}

.home-navLink-button:hover {
    color: #d1c4e9; /* Change color on hover */
}

/* Sidebar styles */
.home-navbar-sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* Hide sidebar off-screen */
    width: 250px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease;
    z-index: 1000; /* Ensure it appears above other content */
}

.home-navbar-sidebar.open {
    left: 0; /* Show sidebar */
}

.home-navbar-close-btn {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
}

.home-navbar-sidebar-link {
    display: block;
    padding: 15px;
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.home-navbar-sidebar-link:hover {
    background-color: #d1c4e9; /* Optional hover effect */
}

.home-navbar-sidebar-link-button {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-family: "Literata", serif;
    background: none;
    border: none;
    padding: 15px;
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.home-navbar-sidebar-link-button:hover {
    background-color: #d1c4e9;
}

/* Hamburger styles */
.home-navbar-hamburger {
    display: none; /* Hidden by default */
    flex-direction: column;
    cursor: pointer;
}

.home-navbar-hamburger-line {
    width: 25px;
    height: 3px;
    background-color: white; /* Color of the hamburger lines */
    color: white;
    margin: 4px 0;
    transition: 0.4s;
}

/* Hero section styles */
.landingPageHero {
    background-image: url("https://images.unsplash.com/photo-1487640228478-7a32e30a9e40?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height */
    width: 100vw; /* Full width */
    margin: 0; /* Remove margins */
    position: relative;
    box-shadow: inset 0 0 0 2000px rgba(105, 8, 201, 0.25);
    text-align: center;
}

/* Hero text styles */
.landingPageHero h1 {
    font-size: 56px;
    font-weight: bolder;
    margin-bottom: 0;
}

.landingPageHeroSubtitle {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 75px;
}

.landingPageHero p {
    font-size: 18px;
    margin: 15px 0;
    max-width: 550px;
    letter-spacing: 1px;
}

.signUpForm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.signUpForm input[type="email"] {
    padding: 12px;
    font-size: 18px;
    font-family: "Literata", serif;
    border: none;
    border-radius: 5px 0 0 5px;
    outline: none;
}

.signUpForm button {
    padding: 12px 24px;
    font-size: 18px;
    font-family: "Literata", serif;
    border: 1px solid black;
    border-radius: 0 5px 5px 0;
    background-color: #eddef8;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.signUpForm button:hover {
    background-color: #d1c4e9;
    color: white;
}

/* Footer styles */
footer {
    background-color: black;
    padding: 20px;
    text-align: center;
    color: #999;
    width: 100vw; /* Full width */
    margin: 0; /* Remove margins */
    position: relative;
}

.landingPageNavBarHamburger img {
    height: 50px; /* Set the height to 50px */
    width: auto; /* Maintain aspect ratio */
}

/* Media query for mobile */
@media (max-width: 768px) {
    .home-navbar-hamburger {
        display: flex; /* Show hamburger icon on mobile */
        margin-left: 20px;
    }

    .landingPageNavBarLeft {
        display: none; /* Hide left links on mobile */
    }

    .home-navLink-logo {
        display: flex;
    }

    .landingPageNavBarRight {
        display: none; /* Hide right links on mobile */
    }

    .landingPageHero h1 {
        font-size: 48px;
    }

    .landingPageHeroSubtitle {
        margin-left: 20px;
        margin-right: 20px;
    }

    .signUpForm {
        flex-direction: column; /* Stack input and button vertically */
        align-items: center; /* Make them stretch to full width */
        margin: 10px; /* Add some margin for spacing */
    }

    .signUpForm input[type="email"] {
        width: 90%; /* Set width to 90% of the container */
        padding: 10px; /* Reduce padding for mobile */
        margin-bottom: 10px; /* Add space between input and button */
        border-radius: 5px;
    }

    .signUpForm button {
        width: 70%; /* Set width to 90% of the container */
        padding: 10px; /* Reduce padding for mobile */
        border-radius: 5px;
    }
}

/* Add these styles to your home.css file */

/* Target the form-container specifically within the landingPageHero */
.landingPageHero .form-container {
    background-color: transparent;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: auto;
    max-width: none;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Style the input and button within the hero's form-container */
.landingPageHero .form-container input[type="email"] {
    padding: 12px;
    font-size: 18px;
    font-family: "Literata", serif;
    border: none;
    border-radius: 5px 0 0 5px;
    outline: none;
    width: 300px;
    box-shadow: none;
}

.landingPageHero .form-container button[type="submit"] {
    padding: 12px 24px;
    font-size: 18px;
    font-family: "Literata", serif;
    border: 1px solid black;
    border-radius: 0 5px 5px 0;
    background-color: #eddef8;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.landingPageHero .form-container button[type="submit"]:hover {
    background-color: #d1c4e9;
    color: white;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .landingPageHero .form-container {
        flex-direction: column;
        width: 90%;
    }
    
    .landingPageHero .form-container input[type="email"] {
        width: 100%;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    .landingPageHero .form-container button[type="submit"] {
        width: 70%;
        border-radius: 5px;
    }
}
