.employee-directory-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.employee-directory-back-wrapper {
    align-self: flex-start;
}

.employee-directory-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.employee-directory {
    padding: 20px;
    padding-top: 0;
    max-width: 800px;
    width: 100%;
}

.org-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.employee {
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.employee-box {
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    position: relative;
    z-index: 1;
}

.subordinates {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.subordinate {
    position: relative;
    margin: 0 40px;
}

.line {
    position: absolute;
    top: -20px;
    left: 50%;
    width: 2px;
    height: 20px;
    background: #ccc;
    transform: translateX(-50%);
}

.employee-name {
    font-size: 1.5em;
    margin: 0;
}

.employee-position {
    color: #666;
}

.employee-directory ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    width: 100%;
}

.employee-directory li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 15px;
    font-size: 16px;
    text-indent: 0;
    display: flex;
    align-items: center;
}

.employee-directory li:before {
    content: "\2605";
    color: #6908C9;
    position: absolute;
    left: 0;
    top: 0;
}

.employee-directory li a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
}

.employee-directory li a:hover {
    color: #6908C9;
}

.page-header {
    text-align: left;
    margin-bottom: 30px;
    color: #333;
}

