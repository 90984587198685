.support-body {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.support-title {
    font-size: 28px;
    margin-bottom: 1.5rem;
    color: #6908C9;
    text-align: center;
    font-weight: 600;
}

.support-container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid #dcdcdc;
    color: #504a5a;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease;
}

.support-container:hover {
    border-color: #6908C9;
    box-shadow: 0 5px 15px rgba(105, 8, 201, 0.08);
    transform: translateY(-2px);
}

.support-section {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #f0f0f0;
}

.support-section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.support-subtitle {
    font-size: 22px;
    margin-bottom: 1rem;
    color: #6908C9;
    font-weight: 500;
}

.support-text {
    font-size: 16px;
    line-height: 1.6;
    color: #504a5a;
}

.support-link {
    color: #6908C9;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
    position: relative;
    margin-left: 5px;
}

.support-link:hover {
    color: #6908C9;
    text-decoration: none;
}

.support-link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #6908C9;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
}

.support-link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .support-container {
        padding: 1.5rem;
    }
    
    .support-title {
        font-size: 24px;
    }
    
    .support-subtitle {
        font-size: 20px;
    }
    
    .support-text {
        font-size: 15px;
    }
}
