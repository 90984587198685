/* ======================
   Layout & Container Styles 
   ====================== */
.progress-page-body {
    background-color: white;
    min-height: 100vh;
    padding: 2rem;
}

.page-content {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}

.progress-page-content {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}

.progress-page-back-wrapper {
    align-self: flex-start;
    width: fit-content;
}

/* ======================
   Header Styles 
   ====================== */
.progress-page-header {
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-page-header-text {
    font-size: 28px;
    color: black;
    margin-bottom: 0.5rem;
}

/* ======================
   Goal Card Styles 
   ====================== */
.goal-card {
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease;
    width: 100%;
    box-sizing: border-box;
}

.goal-card:hover {
    border-color: #6908C9;
    box-shadow: 0 2px 8px rgba(105, 8, 201, 0.1);
}

.progress-page-goal-name {
    font-size: 20px;
    color: #504a5a;
    margin-bottom: 1rem;
}

.progress-page-goal-text {
    color: #504a5a;
    font-size: 16px;
    line-height: 1.5;
    margin: 1rem 0;
    padding: 1rem;
    background: #faf7fd;
    border-radius: 4px;
}

/* ======================
   Progress Bar Styles 
   ====================== */
.progress-bar-container {
    margin: 1.5rem 0;
}

.progress-page-goal-progress {
    width: 100%;
    height: 8px;
    accent-color: #6908C9;
    margin-bottom: 0.5rem;
}

.progress-percentage {
    color: #6908C9;
    font-weight: 500;
    font-size: 16px;
}

/* ======================
   Comments Section Styles 
   ====================== */
.comments-section {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #dcdcdc;
    width: 100%;
}

.progress-page-goal-comment {
    width: 100%;
    min-height: 100px;
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 16px;
    resize: vertical;
    margin: 1rem 0;
    transition: border-color 0.2s ease;
    box-sizing: border-box;
}

.progress-page-goal-comment:focus {
    border-color: #6908C9;
    outline: none;
    box-shadow: 0 0 0 2px rgba(105, 8, 201, 0.1);
}

/* Improved Comment History (chat-like interface) */
.comment-history {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 12px;
    max-height: 350px;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
}

.comment-item {
    padding: 1rem;
    border-radius: 12px;
    position: relative;
    width: 80%;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    animation: fadeIn 0.3s ease;
    box-sizing: border-box;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.comment-item.manager {
    align-self: flex-end;
    background-color: #6908C9;
    color: white;
    border-bottom-right-radius: 4px;
}

.comment-item.employee {
    align-self: flex-start;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
}

/* Adjust metadata to prevent overlapping */
.comment-metadata {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    margin-bottom: 8px;
}

.comment-author {
    font-weight: 600;
    margin-bottom: 2px;
}

.comment-time {
    color: rgba(255,255,255,0.7);
    font-size: 0.75rem;
}

.comment-item.employee .comment-time {
    color: #888;
}

.comment-content {
    line-height: 1.4;
    word-break: break-word;
}

.pending-comment {
    margin-top: 1rem;
    padding: 1rem;
    background: #eddef8;
    border-radius: 12px;
    font-style: italic;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    border-left: 4px solid #6908C9;
}

.pending-comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.pending-comment-header p {
    margin: 0;
    font-weight: 500;
    color: #6908C9;
    display: flex;
    align-items: center;
    gap: 6px;
}

.clear-feedback-button {
    background: none;
    border: none;
    color: #6908C9;
    font-size: 20px;
    cursor: pointer;
    padding: 0 5px;
    line-height: 1;
    transition: color 0.2s ease;
}

.clear-feedback-button:hover {
    color: #5607a3;
}

/* Feedback composer */
.feedback-composer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
    border: 1px solid #e0e0e0;
}

.feedback-composer-header {
    font-weight: 600;
    color: #504a5a;
    display: flex;
    align-items: center;
    gap: 8px;
}

.feedback-composer-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.no-comments-message {
    text-align: center;
    padding: 20px 0;
    font-style: italic;
    color: #888;
}

/* ======================
   Button Styles 
   ====================== */
.action-button {
    background: none;
    border: 2px solid #6908C9;
    color: #6908C9;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 0.5rem;
}

.action-button:hover {
    background: #eddef8;
}

.action-button.primary {
    background: #6908C9;
    color: white;
}

.action-button.primary:hover {
    background: #5607a3;
}

.progress-page-submit-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.progress-page-submit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
}

.progress-page-submit-link:hover {
    color: #d1c4e9;
}

/* ======================
   Media Queries 
   ====================== */
@media screen and (max-width: 768px) {
    .progress-page-body {
        padding: 1rem;
    }
    
    .goal-card {
        padding: 1rem;
    }
    
    .comment-actions {
        flex-direction: column;
    }
    
    .action-button {
        width: 100%;
    }

    .pending-comment {
        margin: 1rem 0;
    }

    .pending-comment-header {
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {
    .progress-page-goal-comment-button,
    .progress-page-goal-text-toggle-button,
    .progress-page-goal-confirm-button {
        width: 150px;
        height: 35px;
        font-size: 14px;
    }

    .progress-page-header {
        font-size: 20px;
    }

    .progress-page-goal-name {
        font-size: 18px;
    }

    .progress-page-goal-text {
        font-size: 14px;
    }

    .progress-page-submit-link {
        font-size: 18px;
    }

    .progress-page-goal-comment {
        font-size: 14px;
    }
}

/* In the Comments Section Styles */
.comment-actions {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.pending-comment {
    margin-top: 1rem;
    padding: 1rem;
    background: #eddef8;
    border-radius: 8px;
    font-style: italic;
}

.pending-comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.pending-comment-header p {
    margin: 0;
    font-weight: 500;
}

.clear-feedback-button {
    background: none;
    border: none;
    color: #6908C9;
    font-size: 20px;
    cursor: pointer;
    padding: 0 5px;
    line-height: 1;
    transition: color 0.2s ease;
}

.clear-feedback-button:hover {
    color: #5607a3;
}

/* Evidence button and verification status */
.progress-page-verification-controls {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

.progress-page-add-verification-button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.2s;
}

.progress-page-add-verification-button:hover {
    background-color: #e0e0e0;
}

.progress-page-verification-status {
    margin-left: 10px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.progress-page-status-pending {
    color: #f39c12;
}

.progress-page-status-verified {
    color: #27ae60;
}

.progress-page-status-rejected {
    color: #e74c3c;
}

/* Verification modal */
.progress-page-verification-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.progress-page-verification-modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.progress-page-verification-form {
    margin: 20px 0;
}

.progress-page-form-group {
    margin-bottom: 15px;
    width: 100%;
}

.progress-page-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.progress-page-form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 100px;
    resize: vertical;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
}

.progress-page-form-group textarea:focus {
    border-color: #6908C9;
    outline: none;
    box-shadow: 0 0 0 2px rgba(105, 8, 201, 0.1);
}

.progress-page-form-group small {
    display: block;
    margin-top: 5px;
    color: #666;
    font-size: 0.8rem;
}

.progress-page-verification-modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

/* Verification notes section */
.progress-page-verification-notes-section {
    margin: 15px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 4px;
}

.progress-page-verification-notes-section h4 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
}

.progress-page-verification-notes-section p {
    margin: 0;
    font-style: italic;
    color: #555;
    line-height: 1.4;
}

/* Spinner animation */
.progress-page-fa-spin {
    animation: progress-page-fa-spin 2s infinite linear;
}

@keyframes progress-page-fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

/* Add these styles for the check-in feature */

/* Check-in toggle button */
.progress-page-checkin-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;
    margin: 10px auto 0;
}

.progress-page-checkin-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

/* Check-in section */
.progress-page-checkin-section {
    margin-top: 0;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.progress-page-checkin-section h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: #333;
}

/* Check-in list */
.checkin-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkin-item {
    padding: 15px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.checkin-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.upcoming-checkin {
    border-left: 4px solid #4a6cf7;
}

.past-checkin {
    border-left: 4px solid #4caf50;
}

.cancelled-checkin {
    border-left: 4px solid #f44336;
    opacity: 0.7;
}

.checkin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.checkin-date {
    font-weight: 500;
}

.checkin-status {
    display: flex;
    align-items: center;
}

.status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
}

.upcoming-checkin .status-badge {
    background-color: #e3f2fd;
    color: #1976d2;
}

.past-checkin .status-badge {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.cancelled-checkin .status-badge {
    background-color: #ffebee;
    color: #c62828;
}

.checkin-completed-date,
.checkin-manager-notes,
.checkin-employee-notes,
.checkin-related-goals {
    margin: 10px 0;
    font-size: 0.9rem;
}

.checkin-manager-notes p,
.checkin-related-goals ul {
    margin: 5px 0;
    padding-left: 15px;
}

.checkin-employee-notes textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 0.9rem;
    margin-top: 5px;
    resize: vertical;
    box-sizing: border-box;
}

.save-notes-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 10px;
}

.save-notes-button:hover {
    opacity: 0.9;
}

/* Responsive styles */
@media (max-width: 768px) {
    .progress-page-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .progress-page-checkin-button {
        margin-left: 0;
        margin-top: 10px;
    }
    
    .checkin-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .checkin-status {
        margin-top: 5px;
    }
}

/* Archive button styles */
.archive-goal-button {
    background-color: #e0e0e0;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 10px;
    transition: all 0.2s ease;
}

.archive-goal-button:hover {
    background-color: #d0d0d0;
    transform: translateY(-2px);
}

.goal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

/* Archive confirmation modal */
.archive-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.archive-modal {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.archive-modal h3 {
    margin-top: 0;
    color: #333;
}

.archive-note {
    color: #666;
    font-style: italic;
    margin: 1rem 0;
}

.archive-modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.archive-confirm-button {
    background-color: #ff5252;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;
}

.archive-confirm-button:hover {
    background-color: #ff3b3b;
}

.archive-cancel-button {
    background-color: #e0e0e0;
    color: #333;
    border: none;
    border-radius: 5px;
    padding: 10px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.archive-cancel-button:hover {
    background-color: #d1d1d1;
}
